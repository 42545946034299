import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Card, RenderHTML } from '../../index'
import { abbreviate, toFixed, ENTITY_TYPE, getEaIntegrationEnabledFlag, getLocalizedCurrency } from '../../../utils'


const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]).isRequired,
  data: PropTypes.shape({
    sector: PropTypes.string,
    capGroup: PropTypes.string,
    currentHoldings: PropTypes.object,
    peerAnalysis: PropTypes.object,
    analytics: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    webcast: PropTypes.array
  }).isRequired
}

const defaultProps = {
  isLoading: false,
  entityType: INSTITUTION,
  data: {}
}

/**
 * Get Change Percentage
 * @param current
 * @param change
 * @returns {*}
 */
function getPercentage (current, change) {
  if (!get(current, 'sum', null)) {
    return {
      percentage: '0%',
      wording: 'change',
      className: ''
    }
  }

  const percentage = get(change, 'sum') * 100 / get(current, 'sum')

  return {
    percentage: `${toFixed(percentage, 2)}%`,
    wording: percentage ? (percentage > 0) ? 'increase' : 'decrease' : 'change',
    className: percentage ? (percentage > 0) ? '--positive' : '--negative' : ''
  }
}

/**
 * Highlight Component
 * @param condition
 * @param value
 * @returns {*}
 */
function Highlight ({ condition, value }) {
  const prefix = '-'
  return (condition) &&
    <div className='card_list'>
      <div className='card_list-label'>
        <RenderHTML html={`${prefix} ${value}`} />
      </div>
    </div>
}

/**
 * get Web Analytics message based on flag
 * @param analytics
 * @returns {String}
 */
function getAnalyticMessage (analytics) {

  if (getEaIntegrationEnabledFlag() && analytics) {
    return 'Visited website in the last 90 days'

  } else if (analytics) {
    return `Visited Website ${analytics} times in the last 90 days`
  }

  return 'Not a known website visitor in the last 90 days'
}

/**
 * Highlight Card Component
 * @param props
 */
function HighlightCard (props) {
  const { dataId, theme, isLoading, entityType, data } = props
  const { sector, capGroup, currentHoldings, peerAnalysis, analytics, webcast } = data

  const { marketValue, current, qtrChange } = peerAnalysis || {}
  const { average, count, sum } = marketValue || {}

  const peersPercentage = getPercentage(current, qtrChange)
  const holdingsPercentage = getPercentage(get(currentHoldings, 'marketValue'), get(currentHoldings, 'marketValueQtrChange'))

  let webcastText = 'Have not attended a Webcast in the last year'
  if (webcast && webcast.length > 0) {
    webcastText = `Attended ${webcast[0]} ${(webcast[0] > 1) ? 'Webcasts' : 'Webcast'} in the last year`
    if (getEaIntegrationEnabledFlag()) {
      webcastText = 'Attended webcast in the last year'
    }
  }

  const highlights = [
    { condition: !!count, value: `Holds ${count} ${count > 1 ? 'peers' : 'peer'} valued at ${abbreviate(sum, 1)} ${getLocalizedCurrency()}` },
    { condition: !count, value: 'Does not hold any peers' },
    { condition: !!average, value: `Holds an average position of ${abbreviate(average, 1)} ${getLocalizedCurrency()} in your peer group` },
    {
      condition: false, // !!count - temp disabled DESKTOP-21868
      value: `<span class=${`card_list-value${peersPercentage.className}`}>${peersPercentage.percentage}</span> ${peersPercentage.wording} in your peer group this quarter`
    },
    {
      condition: false, // temp disabled DESKTOP-21868
      value: `<span class=${`card_list-value${holdingsPercentage.className}`}>${holdingsPercentage.percentage}</span> ${holdingsPercentage.wording} in ${capGroup} - ${sector} this quarter`
    },
    {
      condition: (entityType === INSTITUTION),
      value: getAnalyticMessage(analytics)
    },
    {
      condition: (entityType === CONTACT),
      value: webcastText

    }
  ]

  return (
    <Card
      dataId={dataId}
      theme={theme}
      isLoading={isLoading}
      title='Highlights'
    >
      {!isLoading && highlights.map((highlight, index) => {
        return <Highlight key={`highlight-${index}`} {...highlight} />
      })}
    </Card>
  )
}

HighlightCard.propTypes = propTypes
HighlightCard.defaultProps = defaultProps

export default memo(HighlightCard)
