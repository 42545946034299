import { push } from 'connected-react-router'
import { config } from '../config'
import { ACCESS_TOKEN } from '../services/auth.service'
import { omit } from 'lodash'
import { auth0Helper } from '../services/auth0.service'
import { getOrganizationIdFromAuth0Token } from '../utils'

const qs = require('query-string')

export const CALL_API = 'CALL_API'
export const METHOD_TYPE = { GET: 'GET', PUT: 'PUT', POST: 'POST', DELETE: 'DELETE' }

/**
 * Call API
 * @param endpoint
 * @param authenticatedRequest
 * @param payload
 * @param method
 * @param token
 * @return {Promise.<TResult>}
 */
const callApi = async (endpoint, authenticatedRequest, payload, method, token, queryOptions = {}, orgIdVerifier ='') => {
  let queryString = ''
  const orgId = orgIdVerifier || getOrganizationIdFromAuth0Token(token)
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-control': 'no-cache, no-store, must-revalidate, max-age=-1, private',
      Pragma: 'no-cache',
      Expiries: '-1',
      'x-org-Id': orgId
    },
    method,
    credentials: 'include'
  }

  if (authenticatedRequest) {
    options.headers.Authorization = `Bearer ${token}`
  }

  if (payload) {
    if (METHOD_TYPE.GET === method) {
      queryString = `?${qs.stringify(payload, queryOptions)}`
    }

    if ([METHOD_TYPE.POST, METHOD_TYPE.PUT, METHOD_TYPE.DELETE].indexOf(method) > -1) {
      options.body = JSON.stringify(payload, queryOptions)
    }
  }

  const url = config.apiUrl + endpoint + queryString
  const response = await fetch(url, options)
  const json = await response.json()

  if (!response.ok) {
    return Promise.reject(Object.assign({}, { status: response.status }, json))
  }

  if (json && json.success && 'data' in json) {
    const meta = omit(json, 'data')
    const response = {
      data: json.data,
      meta
    }

    return Promise.resolve(response)
  }

  if (json && 'success' in json && !json.success) {
    return Promise.reject(response)
  }

  return Promise.resolve({ data: json })
}

export default () => (next) => async (action) => {
  if (process.env.JEST_WORKER_ID !== undefined && action === undefined) {
    return
  }

  const callAPI = action[CALL_API]
  const timestamp = new Date().getTime()

  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let { endpoint, types, payload, authenticatedRequest, method, queryOptions } = callAPI

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  if (typeof authenticatedRequest === 'undefined') {
    authenticatedRequest = true
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }

  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  if (!method || !METHOD_TYPE[method]) {
    throw new Error('Invalid or missing method type.')
  }

  const [requestType, successType, failureType] = types

  function actionWith (data) {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  function doError (data) {
    if (data.status === 401) {
      if (data?.error === 'Organization mismatch') { // Force reload to clean up browser requests cache
        window.location.href = '/logout?headless=true'
        return
      }

      return Promise.all([next(data), next(push('/logout'))])
    } else {
      return next(data)
    }
  }

  const token = auth0Helper.useAuth0SDK()
    ? await auth0Helper.getAccessTokenSilently()() //add params as getAccessTokenSilently()({audience: '', scope: ''})
    : localStorage.getItem(ACCESS_TOKEN)

  next(actionWith({ type: requestType, payload, timestamp }))

  return callApi(endpoint, authenticatedRequest, payload, method, token, queryOptions).then(
    (response) => {
      return (next)(actionWith({
        payload: (response && response.data) || {},
        meta: (response && response.meta) || {},
        authenticatedRequest,
        type: successType,
        request: payload,
        timestamp
      }))
    }
  ).catch((error) => (doError)(actionWith({
    type: failureType,
    error: error.message || 'Error!',
    status: error.status,
    request: payload,
    timestamp
  })))
}
