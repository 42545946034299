import {
  SET_APP_FULLSCREEN,
  SET_SENCHA_FULLSCREEN
} from '../../actions'
import { LOCATION_CHANGE } from 'connected-react-router'

// Pages that, if navigated through, will persist fullscreen enabled state
const persistentPages = ['/builder/:id']

const initialState = {
  enabled: false,
  senchaEnabled: false
}

const fullscreen = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_FULLSCREEN:
      return {
        ...state,
        enabled: action.payload
      }
    case SET_SENCHA_FULLSCREEN:
      return {
        ...state,
        senchaEnabled: action.payload
      }
    case LOCATION_CHANGE:
      const { location: { pathname } } = action && action.payload
      const isPersistentPage = persistentPages.find((page) => page.startsWith(`/${pathname.split('/')[1]}/`))

      return isPersistentPage ? state : initialState
    default:
      return state
  }
}

export default fullscreen
