import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import { useCurrentHoldingQuery, usePeerAnalysisQuery } from '../hook'
import { fetchEntityHighlights, resetEntityHighlights, statusType } from '../../../actions'

// components
import HighlightCard from './highlight.component'
import { getActiveTicker, ENTITY_TYPE, THEMES, getLocalizedCurrency } from '../../../utils'
import { get, keys } from 'lodash'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  entity: PropTypes.object,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]).isRequired
}

const defaultProps = {
  entityType: INSTITUTION
}

/**
 * Entity Highlight Component
 * @param props
 */
function EntityHighlight (props) {
  const {
    dataId, theme, entity, entityId, entityType, tickerId, securityId, sector, capGroup,
    analytics, webcast, fetchEntityHighlights, resetEntityHighlights
  } = props

  const { loading: peerAnalysisLoading, data: peerAnalysis } = usePeerAnalysisQuery({
    variables: {
      tickerId,
      entityId,
      entityType,
      holder: true,
      currencyCode: getLocalizedCurrency()
    }
  })

  const { loading: currentHoldingsLoading, data: currentHoldings } = useCurrentHoldingQuery(entityType, {
    variables: {
      entityId: (entityType === ENTITY_TYPE.CONTACT)
        ? (get(entity, 'managedFunds', []) || []).map((fund) => fund.fundId)
        : entityId,
      sector,
      capGroup,
      currencyCode: getLocalizedCurrency()
    }
  })

  useEffect(() => {
    if (!([CONTACT, INSTITUTION]).includes(entityType) || !entityId || !securityId) {
      return
    }

    fetchEntityHighlights(entityId, entityType, {
      securityId,
      type: (entityType === INSTITUTION) ? ['analytics'] : ['webcast']
    })

    return () => {
      resetEntityHighlights()
    }
  }, [entityId, entityType, securityId, fetchEntityHighlights, resetEntityHighlights])

  return (
    <HighlightCard
      dataId={`${dataId}HighlightsCard`}
      theme={theme}
      isLoading={[currentHoldingsLoading, peerAnalysisLoading, get(analytics, 'status') === statusType.IN_PROGRESS].some((item) => !!item)}
      entityType={entityType}
      data={{
        sector,
        capGroup,
        currentHoldings,
        peerAnalysis,
        analytics: get(analytics, 'sessions'),
        webcast: keys(webcast).map((email) => webcast[email])
      }}
    />
  )
}

EntityHighlight.propTypes = propTypes
EntityHighlight.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    tickerId: get(ticker, 'q4_ticker_id'),
    securityId: get(ticker, 'q4_entity_id'),
    sector: get(ticker, 'sector'),
    capGroup: get(ticker, 'cap_group'),
    analytics: get(state, 'targeting.detail.highlights.analytics'),
    webcast: get(state, 'targeting.detail.highlights.webcast')
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchEntityHighlights: bindActionCreators(fetchEntityHighlights, dispatch),
  resetEntityHighlights: bindActionCreators(resetEntityHighlights, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EntityHighlight)
