export const ROUTE_PATH_ID_LABEL = {
  CODE: ':code',
  DAYS: ':days',
  ID: ':id',
  SECURITY_ID: ':securityId'
}
const { CODE, DAYS, ID, SECURITY_ID } = ROUTE_PATH_ID_LABEL

export const ROUTE_PATH = {
  ROOT: '/',
  ACTIVITY: '/activity',
  ACTIVITY_WITH_ID: `/activity/${ID}`,
  ANALYTICS: '/analytics',
  ANALYTICS_WITH_DAYS: `/analytics/${DAYS}`,
  ANALYTICS_WITH_DAYS_AND_ID: `/analytics/${DAYS}/${ID}`,
  BRIEFING_BOOK: '/briefingbook',
  BRIEFING_BOOK_WITH_ID: `/briefingbook/${ID}`,
  CONTACT: '/contact',
  CONTACT_WITH_ID: `/contact/${ID}`,
  CORPORATE_PARTICIPANT_WITH_ID: `/corporate-participant/${ID}`,
  DASHBOARD: '/dashboard',
  DASHBOARD_EDIT: '/dashboard/edit',
  ENGAGEMENT_ANALYTICS: '/engagement',
  PIPELINE: '/pipeline',
  DEAL_WITH_ID: `/deal/${ID}`,
  ESTIMATES_WITH_SECURITY_ID: `/estimates/${SECURITY_ID}`,
  EVENTS_TRANSCRIPTS: '/event',
  FUND_WITH_ID: `/fund/${ID}`,
  INSTITUTION_WITH_ID: `/institution/${ID}`,
  SEARCH: '/search',
  SECURITY_WITH_ID: `/security/${ID}`,
  TARGETING: '/targeting',
  PEERLIST: '/peerlist',
  WIDGET: '/widget',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PASSWORD_RESET: '/password-reset',
  ERROR_WITH_CODE: `/error/${CODE}`,
  PAGE_NOT_FOUND: '/error/404',
  REPORT_BUILDER: '/builder',
  REPORT_BUILDER_WITH_ID: `/builder/${ID}`,
  USE_SSO_LOGIN: '/use-sso-login',
  USE_LEGACY_LOGIN: '/use-legacy-login',
  WEBCAST: '/webcast',
  WEBCAST_WITH_ID: `/webcast/${ID}`,
}

export const LEGACY_ROUTE_PATH = {
  REPORT_BUILDER: '/report',
  REPORT_BUILDER_WITH_ID: `/report/${ID}`
}

export const SENCHA_ROUTE = {
  ANALYTICS: '/analytics',
  ANALYTICS_WITH_DAYS: `/analytics/${DAYS}`,
  ANALYTICS_WITH_DAYS_AND_ID: `/analytics/${DAYS}/${ID}`,
  CALENDAR: '/calendar',
  COMPANY_WITH_ID: `/company/${ID}`,
  COMPANY_OWNERSHIP_WITH_ID: `/company/${ID}/ownership`,
  COMPANY_SENTIMENT_WITH_ID: `/company/${ID}/sentiment`,
  COMPANY_VOLATILITY_WITH_ID: `/company/${ID}/volatility`,
  COMPANY_ACTIVIST_WITH_ID: `/company/${ID}/activist`,
  COMPANY_RANGES_WITH_ID: `/company/${ID}/expected-ranges`,
  COMPANY_PERFORMANCE_WITH_ID: `/company/${ID}/relative-performance`,
  RESEARCH: '/research',
  SHAREHOLDERID: '/shareholderid',
  TEAM: '/team',
  RESEARCH_WITH_ID: `/research/${ID}`,
  WEBCAST: '/webcast',
  WEBCAST_WITH_ID: `/webcast/${ID}`,
  CREATE_DEAL: '/create-deal',
  EDIT_DEAL: '/edit-deal'
}

export const CRM_EMAIL_ROUTE = {
  EMAIL: '/email',
  MANAGE_EMAIL_CONNECTION: '/manage-email-connection',
}

export const CRM_MANAGE_EMAIL_ROUTE = {
  MANAGE_CONNECTION: '/manage-email-connection'
}
