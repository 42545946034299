import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from 'antd'
import { getClassName, getLocalizedFormat, THEMES } from '../../../utils'

import '../datePicker.scss'
import './dateRangePicker.component.scss'

const { RangePicker } = DatePicker

const propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf([
    THEMES.LIGHT,
    THEMES.DARK,
    THEMES.WHITE,
    THEMES.RAIN,
    THEMES.INK
  ]),
  label: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large']), // 32, 40
  mode: PropTypes.array,
  separator: PropTypes.string,
  format: PropTypes.string,
  placeholder: PropTypes.array,
  error: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  defaultValue: PropTypes.array,
  value: PropTypes.array,
  allowEmpty: PropTypes.arrayOf(PropTypes.bool),
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

const defaultProps = {
  theme: THEMES.LIGHT,
  size: 'large',
  mode: ['date', 'date'],
  separator: '-',
  format: 'MM/DD/YYYY',
  placeholder: ['Select date range', ''],
  error: {},
  allowEmpty: [false, false],
  clearable: false,
  disabled: false,
  required: false
}

/**
 * DateRangePicker Component
 * @param props
 */
function DateRangePicker (props) {
  const {
    className, theme, size, mode, format, separator, placeholder, error: { isError },
    defaultValue, value, allowEmpty, clearable, disabled, onChange
  } = props

  return (
    <RangePicker
      className={getClassName('date-picker date-range-picker', [
        { condition: className, trueClassName: className },
        { condition: theme, trueClassName: `date-picker--${theme} date-range-picker--${theme}` },
        { condition: value.some((date) => date), trueClassName: 'date-range-picker--sized' },
        { condition: isError, trueClassName: 'date-picker--error' }
      ])}
      dropdownClassName={getClassName('date-picker-modal date-range-picker-modal', [
        { condition: className, trueClassName: className },
        { condition: theme, trueClassName: `date-picker-modal--${theme} date-range-picker-modal--${theme}` }
      ])}
      size={size}
      mode={mode}
      format={getLocalizedFormat(format)}
      separator={value.every((date) => date) ? separator : ''}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      allowEmpty={allowEmpty}
      allowClear={clearable}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

DateRangePicker.propTypes = propTypes
DateRangePicker.defaultProps = defaultProps

export default memo(DateRangePicker)
