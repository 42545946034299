const LD_ALL_FLAGS = 'ld_all'

export const EA_INTEGRATION_PHASES = {
  PHASE_2: 'phase-2',
  PHASE_3: 'phase-3'
}

export const LD_FEATURE_FLAGS = {
  USE_LOCALIZED_DATE_FORMAT: 'use-localized-date-format',
  USE_SSO: 'use-auth-0',
  EA_INTEGRATION: 'ea-integration-status',
  USE_LOCALIZED_CURRENCY: 'use-localized-currency',
  CRM_EMAIL: 'crm-bulk-email-feature',
  CRM_EMAIL_MANAGE_MENU_OPTION: 'crm-bulk-email-manage-menu-option',
  USE_ELASTIC_CLOUD_SEARCH_ENGINE: 'use-elastic-cloud-search-engine',
  USE_REBRANDED_UI: 'nrhwnjehsd'
}

export const launchDarklyHelper = {
  setLocalFlags: (flags) => {
    localStorage.setItem(LD_ALL_FLAGS, JSON.stringify(flags))
  },
  getLocalFlag: (flag) => {
    const allFlags = JSON.parse(localStorage.getItem(LD_ALL_FLAGS))
    return allFlags?.[flag]
  }
}
