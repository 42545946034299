import { auth0Helper, LOCAL_AUTH_MODE } from "../../services/auth0.service"

/**
 * Set SSO/Legacy as LocalAuthMode and
 * @param { boolean } status Should use SSO?
 * @param { function } getLogoutContainer
 * @param { object } auth
 */
export const setAuthModeSSO = (status, getLogoutContainer, auth) => {
  const { SSO, LEGACY } = LOCAL_AUTH_MODE.VALUE
  const shouldUseSSO = !!status

  if (!shouldUseSSO && auth0Helper.useAuth0SDK()) {
    auth0Helper.setUseAuth0SDK(false)
  }

  const localAuthMode = shouldUseSSO
    ? SSO
    : LEGACY

  localStorage.setItem(LOCAL_AUTH_MODE.KEY, localAuthMode)

  // Logout current user if logged in
  return getLogoutContainer(auth)
}

/**
 *  Get the active token based on the auth mode (SSO/Legacy) 
 * @param {string | object | undefined} legacyToken 
 * @returns  {Promise<string>} access token
 */
export const getActiveToken = async (legacyToken) => {
  const legacyAccessToken = legacyToken?.data || legacyToken
  return auth0Helper.useAuth0SDK()
    ? await auth0Helper.getAccessTokenSilently()()
    : legacyAccessToken
}