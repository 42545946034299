import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Login from '../../components/login/login.component'

class LoginContainer extends Component {
  render () {
    const { history, auth, passResetMessage } = this.props

    return (
      <Login
        history={history}
        auth={auth}
        passResetMessage={passResetMessage}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    passResetMessage: state.login.passwordResetMessage
  }
}

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginContainer))
