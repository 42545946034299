import React from 'react'
import { FormikTextInput } from '../../shared/form/'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import './form.component.css'
import { Button } from '../../index'
import { THEMES } from '../../../utils/ui'

/**
 * Login form componet
 * @param props
 * @returns {*}
 * @private
 */
const _LoginForm = props => {
  const {
    errors,
    status,
    isSubmitting,
    handleSubmit
  } = props

  /**
   * render error messages
   * @param errors
   * @returns {null|*}
   * @constructor
   */
  const RenderError = ({ errors }) => {
    if (!errors) {
      return null
    }
    const error = errors && errors.serverError ? errors.serverError : Object.values(errors)[0]

    return <div className='login-error'>{error}
      {errors.serverError && status && status === 'locked' && <div className='contact-support-message'>
        Contact our <a href="mailto:support@q4inc.com?Subject=Unlock%20Desktop%20Account">Support</a> team to unlock
        your account.
      </div>}
    </div>
  }

  return (
    <>
      <RenderError errors={errors}/>
      <form className='login-page_form' onSubmit={handleSubmit} autoComplete='off'>
        <FormikTextInput
          id='email'
          ui='condensed'
          theme='dark-slate'
          placeholder='Email'
          type='text'
          errorTooltip={false}
          {...props}
        />

        <FormikTextInput
          id='password'
          ui='condensed'
          theme='dark-slate'
          placeholder='Password'
          type='password'
          errorTooltip={false}
          {...props}
        />
        <Button
          type='submit'
          disabled={isSubmitting}
          theme={THEMES.Q4_BLUE}
          tall
          label='Login'
        />
      </form>
    </>
  )
}

const LoginForm = withFormik({
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    email: '',
    password: ''
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address format!')
      .required('Email is required!'),
    password: Yup.string()
      .min(6, 'Password must meet the requirements.') // 6 characters - is for old password policy compatibility. We'll need to change to 10 in the future
      .required('Password is required!')
  }),
  handleSubmit: (values, { props, setErrors, setStatus, setSubmitting }) => {
    const { email, password } = values
    const { history, auth, redirectTo } = props

    auth.login(email, password).then(() => {
      history.push(redirectTo)
    }).catch((response) => {
      setSubmitting(false)

      const errorMessage = response && response.message

      switch (errorMessage) {
        case 'Profile is locked':
          setStatus('locked')
          setErrors({ serverError: 'Your account is temporarily locked because you have reached the maximum number of invalid login attempts.' })
          break;
        default:
          setStatus('unauthorized')
          setErrors({ serverError: 'Invalid email or password' })
      }

    })
  },
  displayName: 'LoginForm' // helps with debugging (React DevTools)
})(_LoginForm)

export default LoginForm
