import React from 'react'
import { config, LDConfig } from './config'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { LicenseManager } from 'ag-grid-enterprise'
import history from './utils/history'
import Root from './pages/index'
import configureStore from './store/configure.store'
import theme from './resources/theme/q4.theme'
import { Auth0Provider } from '@auth0/auth0-react'
import { ApolloProvider } from '@apollo/react-hooks'
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'

import client from './middleware/graphql.middleware'

import { auth0Helper, LOCAL_AUTH_MODE } from './services/auth0.service'
import { launchDarklyHelper, LD_FEATURE_FLAGS } from './services/launchDarkly.service'
import { getLocalStorageItem, isLocalhost, setFavicon, updateCurrencyLocalStorage } from './utils'
import { overrideLogWarn } from './utils/dev.util'

import './resources/styles/global.css'

LicenseManager.setLicenseKey(config.agGridKey)

async function init () {
  const store = await configureStore()
  const LDProvider = await asyncWithLDProvider(LDConfig)

  // Suppress unnecessary warnings on localhost
  if (isLocalhost()) {
    console.warn = overrideLogWarn
  }

  const LaunchDarklyWrapper = () => {
    const ldClient = useLDClient()
    launchDarklyHelper.setLocalFlags(ldClient.allFlags())

    ldClient.on('initialized', () => {
      launchDarklyHelper.setLocalFlags(ldClient.allFlags())
      updateCurrencyLocalStorage(store?.getState()?.profile?.data)
    })

    ldClient.on('change', () => {
      setTimeout(() => {
        launchDarklyHelper.setLocalFlags(ldClient.allFlags())
        updateCurrencyLocalStorage(store?.getState()?.profile?.data)
      }, 400)
    })

    ldClient.on('change', () => {
      setTimeout(() => {
        launchDarklyHelper.setLocalFlags(ldClient.allFlags())
        updateCurrencyLocalStorage(store?.getState()?.profile?.data)
      }, 400)
    })

    // Default to rely on LD
    auth0Helper.setUseAuth0SDK(ldClient.variation(LD_FEATURE_FLAGS.USE_SSO))

    // Overwrite LD with local authentication mode
    const localAuthMode = getLocalStorageItem(LOCAL_AUTH_MODE.KEY)
    if (localAuthMode === LOCAL_AUTH_MODE.VALUE.SSO) {
      auth0Helper.setUseAuth0SDK(true)
    } else if (localAuthMode === LOCAL_AUTH_MODE.VALUE.LEGACY) {
      auth0Helper.setUseAuth0SDK(false)
    }

    // Set favicon based on Q4 logo version
    setFavicon()

    if (!auth0Helper.useAuth0SDK()) return <Root store={store} />

    return (
      <Auth0Provider
        domain={config.auth0Domain}
        useRefreshTokens={true}
        clientId={config.auth0ClientId}
        redirectUri={window.location.origin}
        audience={config.auth0Audience}
        scope={config.auth0Scope}
        cacheLocation={isLocalhost() ? 'memory' : 'localstorage'} // localstorage causes issues on localhost
        brand={!!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_REBRANDED_UI) ? 'peacock' : ''}
      >
        <Root store={store} />
      </Auth0Provider>
    )
  }

  render(
    <LDProvider>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
              <LaunchDarklyWrapper />
            </MuiThemeProvider>
          </ConnectedRouter>
        </Provider>
      </ApolloProvider>
    </LDProvider>,
    document.getElementById('root')
  )
}

init()
