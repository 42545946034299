import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// actions
import { useHistoricalHolderQuery, GET_FUND_HISTORICAL_HOLDERS, GET_INST_HISTORICAL_HOLDERS } from '../../../hook'
import { openModal, modalType } from '../../../../../actions'
import { useExport } from '../../../../../services/csvExport/csvExport.service'

// components
import HistoricalHolderToolbar from './toolbar/toolbar.component'
import HistoricalHolderTable from './table/table.component'

// utils
import { getPageSizeFromStorage, getQuarterEndDates, ENTITY_TYPE, HOLDING_TYPE, formatDate } from '../../../../../utils'
import { get, isNull, omitBy } from 'lodash'

const { FUND, INSTITUTION } = ENTITY_TYPE

const { STAKES, INSTITUTIONS } = HOLDING_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string,
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  tickerId: PropTypes.string.isRequired
}

const defaultProps = {}

const PAGE_SIZE_ID = 'historical-holder-grid'

const CSV_EMPTY_PLACEHOLDER = '0'

/**
 * Historical Holder Grid Component
 * @param props
 */
function HistoricalHolderGrid (props) {
  const { dataIdPrefix, toolbarTheme, toolTheme, tickerId, history, openModal } = props

  const [isSidebar, setIsSidebar] = useState(false)
  const [holderType, setHolderType] = useState('all')
  const entityType = (holderType === FUND) ? FUND : INSTITUTION
  const [state, setState] = useState({
    search: null,
    quarters: 4,
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  })
  const { search, quarters, listOptions } = state

  const holdingType = []
  holderType === 'insider' && holdingType.push(STAKES)
  holderType === INSTITUTION && holdingType.push(INSTITUTIONS)

  const variables = omitBy({
    tickerId,
    quarters,
    search,
    holdingType: holdingType.length ? holdingType : null,
    ...listOptions
  }, isNull)

  const { data, error, loading, client, refetch } = useHistoricalHolderQuery(entityType, { variables })
  const { generateExport, exporting } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  const source = (entityType === FUND) ? FUND : 'inst'
  const holders = get(data, `${source}HoldingHistorical.items`)
  const total = get(data, `${source}HoldingHistorical.count`, 0)

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
  }

  /**
   * Format mapper for CSV export
   * @param holder
   */
  const holdersCSVMapper = (holder) => {
    const entityType = (holderType === FUND) ? FUND : INSTITUTION
    const holderName = get(holder, `${entityType}Name`)
    const style = get(holder, `${entityType}Style`)
    const turnover = get(holder, `${entityType}Turnover`)

    const quarterFields = (getQuarterEndDates(quarters, moment.utc()) || [])
      .map((date, index) => {
        const formattedDate = formatDate(date, undefined, undefined, true)
        return ({
          [`${formattedDate} Position`]: get(holder, `q${(index + 1)}Value`) || CSV_EMPTY_PLACEHOLDER,
          [`${formattedDate} Change`]: get(holder, `q${(index + 1)}Change`) || CSV_EMPTY_PLACEHOLDER
        })
      })
      .reduce((obj, item) => ({ ...obj, ...item }), {})

    const optionalFields = (holderType !== 'insider') ? { Style: style, Turnover: turnover } : {}
    return { 'Holder Name': holderName, ...quarterFields, ...optionalFields }
  }

  /**
   * Handle export
   */
  const handleExport = () => {
    const params = {
      client,
      variables: { ...variables, limit: 0 },
      query: (entityType === FUND) ? GET_FUND_HISTORICAL_HOLDERS(quarters) : GET_INST_HISTORICAL_HOLDERS(quarters),
      dataPath: `data.${source}HoldingHistorical.items`,
      fileName: `${source}_historical_holders.csv`,
      formatter: holdersCSVMapper
    }

    generateExport(params)
  }

  /**
   * handle set holder type
   * @param {string} value
   */
  const handleSetHolderType = (value) => {
    setHolderType(value)
    handleQueryChange({ listOptions: { page: 1, limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10 } })
  }

  return (
    <>
      <HistoricalHolderToolbar
        dataId={`${dataIdPrefix}HistoricalHolders`}
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
        holderType={holderType}
        quarters={quarters}
        search={search}
        noData={!(holders || []).length}
        onSidebarToggle={() => setIsSidebar(!isSidebar)}
        onHolderChange={(holderType) => handleSetHolderType(holderType)}
        onQueryChange={handleQueryChange}
        onExport={handleExport}
        exporting={exporting}
        listOptions={listOptions}
      />
      <HistoricalHolderTable
        pageSizeId={PAGE_SIZE_ID}
        loading={loading}
        data={holders}
        total={total}
        listOptions={listOptions}
        holderType={holderType}
        quarters={quarters}
        onQueryChange={handleQueryChange}
        history={history}
        error={error}
        onRefresh={refetch}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

HistoricalHolderGrid.propTypes = propTypes
HistoricalHolderGrid.defaultProps = defaultProps

export default withRouter(connect(mapDispatchToProps)(HistoricalHolderGrid))
