import React, { Component } from 'react'
import { withAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { REFRESH_TOKEN } from '../../services/auth.service'
import { getBackgroundImage } from '../../utils/splashScreen.util'
import { THEMES, getQ4LogoIcon } from '../../utils'
import LoginForm from './form/form.component'
import ForgotPasswordModal from './forgotPassword/forgotPassword.component'
import { Button } from '../../components'
import { Mask } from '../../components/shared'

import './login.component.css'
import { auth0Helper } from '../../services/auth0.service'

/**
 * Login page component
 */
class Login extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.state = {
      backgroundImage: '',
      showForgotPasswordModal: false,
      isLoadingRefreshToken: false
    }
  };

  /**
   * ComponentDidMount
   * Refresh token
   */
  componentDidMount () {
    const { auth, includeMarketingMessage } = this.props
    const refreshToken = localStorage.getItem(REFRESH_TOKEN)

    this.fetchImage(getBackgroundImage(includeMarketingMessage))

    if (!auth?.isLoggedIn() && refreshToken) {
      this.setState({ isLoadingRefreshToken: true })
      auth?.refreshToken(refreshToken)
        .then(() => this.setState({ isLoadingRefreshToken: false }))
        .catch(() => this.setState({ isLoadingRefreshToken: false }))
    }

    if (!auth?.isLoggedIn() && auth0Helper.useAuth0SDK()) {
      this.props.auth0.loginWithRedirect({ isDesktop: true })
    }
  }

  /**
   * Open forgot password modal
   */
  openForgotPassword = () => {
    this.setState({ showForgotPasswordModal: true })
  }

  /**
   * Close forgot password modal
   */
  closeForgotPassword = () => {
    this.setState({ showForgotPasswordModal: false })
  }

  /**
   * Fetch background image
   * @param src
   */
  fetchImage = (src) => {
    const image = new Image()
    image.src = src

    image.onload = () => this.setState({
      backgroundImage: src
    })
  }

  /**
   * Render Marketing Message
   * Adjust as required based on marketing requirements
   */
  renderMarketingMessage = () => {
    return (
      <div className='marketing-message'>
        <div className='marketing-message_column-container'>
          <div className='marketing-message_column marketing-message_column--left'>
            <div className='marketing-message_title'>Q4's IR Conference</div>
            <div className='marketing-message_detail'>Fall 2020</div>
            <div className='marketing-message_detail'>Pre-register today to get a backstage pass to Q4 product experts
              by attending Q4’s IR Conference. Learn new ways to leverage your solutions and get real insights on
              industry trends while you grow your peer network.
            </div>
          </div>
          <div className='marketing-message_column marketing-message_column--right'>
            <div className='marketing-message_logo-container'>
              <i className={`marketing-message_logo marketing-message_logo--q4 ${getQ4LogoIcon()}`} />
            </div>
            <Button
              theme={THEMES.CITRUS}
              tall
              label='Pre-Register Today'
              linkTo='https://irconference.q4inc.com/home/default.aspx'
              target='_blank'
            />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render Login Component
   * @return {*}
   */
  render () {
    const { history, auth, includeMarketingMessage, passResetMessage } = this.props
    const { backgroundImage, showForgotPasswordModal, isLoadingRefreshToken } = this.state
    const queryParams = qs.parse(history.location.hash)
    const redirectTo = (queryParams && queryParams.redirect) || '/dashboard'

    if (isLoadingRefreshToken) {
      return <Mask visible={true} spinner={true} />
    }

    if (auth?.isLoggedIn()) {
      history.replace(redirectTo)
      return null
    }

    const backgroundImageStyle = {
      backgroundImage: backgroundImage ? `url(${backgroundImage})` : null
    }
    return (
      <div className={`login-page ${auth0Helper.useAuth0SDK() ? 'login-auth0' : ''}`}>
        {auth0Helper.useAuth0SDK() ? (
          <></>
        ) : (
          <>
            {backgroundImage && (
              <div className='login-page_background-image q4-fade-in' style={backgroundImageStyle} />
            )}
            <div className='login-page_content'>
              <i className={`login-page_logo ${getQ4LogoIcon()}`} />
              {passResetMessage && <div className='login-page_success'>{passResetMessage}</div>}
              <LoginForm
                history={history}
                auth={auth}
                redirectTo={redirectTo}
              />
              <Button
                theme='transparent'
                tall
                label='Forgot Password?'
                onClick={this.openForgotPassword}
              />
              {includeMarketingMessage && this.renderMarketingMessage()}
            </div>
            <ForgotPasswordModal
              visible={showForgotPasswordModal}
              onClose={this.closeForgotPassword}
              auth={auth}
            />
          </>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  includeMarketingMessage: PropTypes.bool,
  passResetMessage: PropTypes.string
}

Login.defaultProps = {
  includeMarketingMessage: false
}

export default withLDConsumer()(withAuth0(Login));
