import { format, isReportingWindow } from '../../../../../../../utils'
import { get } from 'lodash'
import React from 'react'

const EMPTY_PLACEHOLDER = '-'

function formatted (value) {
  return (value || value === 0) ? format(value, 0) : EMPTY_PLACEHOLDER
}

/**
 * Title Cell Component
 * @param props
 */
const TitleCell = ({ data }) => {
  const { fundName, institutionName, holderType, holdingType } = (data || {})
  const entityType = (holdingType === 'stakes')
    ? (holderType === 'person') ? 'insider' : 'insider-institution'
    : holderType

  return (
    <div className='cell'>
      <i className={`cell_icon cell_icon--${entityType} q4i-${entityType}-2pt`} />
      <div className='cell_content'>
        <div className='cell_content-name'>{fundName || institutionName}</div>
      </div>
    </div>
  )
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 */
const CustomCell = ({ data = {}, column = {} }) => {
  const colId = get(column, 'colId', '').toLowerCase()
  const cellData = get(data, `${column.colId}`)

  if (colId.includes('name')) {
    return <TitleCell data={data} />
  } else if (colId.includes('value')) {
    return formatted(cellData)
  } else if (colId.includes('change')) {
    const isYetToFile = isReportingWindow() && cellData === 0
    const isLatestQuarterCol = (colId === 'q1change')

    return (isLatestQuarterCol && isYetToFile) ? 'Yet to File' : formatted(cellData)
  }

  return cellData || EMPTY_PLACEHOLDER
}

export default CustomCell
