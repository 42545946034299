import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import queryString from 'query-string'
import {
  getActivityList,
  resetActivityList,
  removeActivities,
  resetActivity,
  fetchContactFilter,
  fetchInstitutionFilter,
  resetContactSuggestions,
  resetInstitutionFilter,
} from '../../../../actions/activity'
import { fetchCorporateParticipants } from '../../../../actions/corporateParticipant'
import { statusType } from '../../../../actions'
import { config } from '../../../../config'
import { debounce, getActiveTicker } from '../../../../utils'

import ActivitySidebar from './sidebar/sidebar.component'
import ActivityToolbar from './toolbar/toolbar.component'
import ActivityList from './list/list.component'

import './grid.container.css'
import { auth0Helper } from '../../../../services/auth0.service'

class ActivityGridContainer extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isSidebarCollapsed: true,
      participants: [],
      filters: {
        limit: 10,
        page: 1,
        search: '',
        sort: {
          property: 'start',
          direction: 'desc'
        },
        category: 'all',
        myActivity: false,
        participants: [],
        contacts: [],
        institutions: [],
        startDate: null,
        endDate: null
      }
    }
  }

  componentDidMount () {
    this.getActivities()
    this.getCorporateParticipants()
  }

  componentDidUpdate (prevProps) {
    const { participants, activityForm, setExportState, activityList } = this.props

    if (prevProps.participants !== participants) {
      this.setState({
        participants: this.getParticipantOptions(participants)
      })
    }

    if (prevProps.activityForm.status !== activityForm.status && activityForm.status === statusType.SUCCESS) {
      this.getActivities()
    }

    if (prevProps.activityList.status !== activityList.status && activityList.status === statusType.SUCCESS) {
      setExportState && activityList.data && activityList.data.length
        ? setExportState(true)
        : setExportState(false)
    }
  }

  componentWillUnmount () {
    this.props.resetActivityList()
  }

  /**
   * Get query params
   * @param params
   * @returns {Promise{[p: string]: *}}
   */
  getQueryParams = async (params = {}) => {
    const { token, securityId } = this.props
    const query = { ...this.state.filters }
    if (query.contacts.length) {
      query.contacts = query.contacts.map(contact => contact.value)
    }
    if (query.institutions.length) {
      query.institutions = query.institutions.map(inst => inst.value)
    }
    if (query.sort) {

      if (query.sort.property === 'start') {
        query.sort.property = 'start.date_time'
      }

      if (query.sort.property === 'address') {
        query.sort.property = 'location'
      }

      query.sort = JSON.stringify([query.sort])
    }

    if (params.export) {
      delete query.page
      delete query.limit
      if (token) {
        query.token = token.data
      }
      if (auth0Helper.useAuth0SDK()) {
        query.token = await auth0Helper.getAccessTokenSilently()()
      }
      if (securityId) {
        query.securityId = securityId
      }
    }

    return query
  }

  /**
   * Get Activities
   * @param query
   */
  getActivities = async (query) => {
    const { fetchActivities } = this.props
    let currentQuery = query || await this.getQueryParams()
    fetchActivities && fetchActivities(currentQuery)
  }

  /**
   * Get Corporate Participants
   */
  getCorporateParticipants = () => {
    const { fetchCorporateParticipants } = this.props
    fetchCorporateParticipants && fetchCorporateParticipants()
  }

  /**
   * Get Activity Contacts
   * @param query
   */
  getContactSuggestions = debounce((query) => {
    const { fetchContactFilter } = this.props
    fetchContactFilter && fetchContactFilter(query)
  }, 600)

  getInstitutionFilter = debounce((query) => {
    const { fetchInstitutionFilter: _fetchInstitutionFilter } = this.props
    _fetchInstitutionFilter && _fetchInstitutionFilter(query)
  }, 600)

  /**
   * Normalize participant dropdown options
   * @param participants
   * @returns {{label: (string|*), value: *, selected: (*|boolean)}[]}
   */
  getParticipantOptions = (participants) => {
    return (participants || []).map((participant) => {
      return {
        label: participant.full_name,
        value: participant._id
      }
    })
  }

  /**
   * Handle toggling of isSidebarCollapsed state
   */
  toggleSidebar = () => {
    const { isSidebarCollapsed } = this.state
    this.setState({
      isSidebarCollapsed: !isSidebarCollapsed
    })
  }

  /**
   * Filter activities based on changes in filter state params
   * @param filters
   */
  handleFilterChange = (filters) => {
    this.setState(
      { filters: { ...this.state.filters, ...filters } },
      () => this.getActivities()
    )
  }

  /**
   * Reset filters
   */
  handleFilterReset = () => {
    const { participants } = this.props

    this.setState({
      participants: this.getParticipantOptions(participants),
      filters: {
        ...this.state.filters, ...{
          page: 1,
          category: 'all',
          myActivity: false,
          contacts: [],
          institutions: [],
          participants: [],
          startDate: null,
          endDate: null
        }
      }
    },
      () => this.getActivities()
    )
  }

  /**
   * Handle activity bulk delete
   * @param ids
   */
  handleRemoveActivities = async (ids) => {
    await this.props.removeActivities(ids)
    this.props.resetActivity()
    this.handleFilterChange({ page: 1 })
  }

  /**
   * Handle grid csv export
   */
  handleExport = async () => {
    const query = await this.getQueryParams({ export: true })
    window.open(`${config.exportApiUrl}/crm/activity/export?${queryString.stringify(query)}`, '_self')

  }

  /**
   * Render Activity Grid
   */
  render () {
    const {
      activityList: {
        data,
        total,
        status,
        counts,
        initialLoading,
        contactFilter,
        contactFilterLoading,
        institutionFilter,
        institutionFilterLoading,
      },
      resetContactSuggestions,
      resetInstitutionFilter,
      history,
    } = this.props
    const { isSidebarCollapsed, filters, participants } = this.state

    const contactSelector = {
      getContactSuggestions: this.getContactSuggestions,
      contactFilter: contactFilter || [],
      contactFilterLoading,
      resetContactSuggestions
    }

    const institutionSelector = {
      institutionFilterValues: institutionFilter || [],
      loading: institutionFilterLoading,
      onInputChange: this.getInstitutionFilter,
      resetInstitutionFilter
    }

    return (
      <div className='activity-page_grid'>
        <ActivitySidebar
          counts={counts}
          filters={filters}
          contactSelector={contactSelector}
          institutionSelector={institutionSelector}
          participants={participants}
          isSidebarCollapsed={isSidebarCollapsed}
          onFilterChange={this.handleFilterChange}
          onFilterReset={this.handleFilterReset}
        />

        <div className='activity-page_grid_body'>
          <ActivityToolbar
            noData={!data || !data.length}
            toggleSidebar={this.toggleSidebar}
            handleFilterChange={this.handleFilterChange}
            handleExport={this.handleExport}
          />
          <ActivityList
            isGridReady={!initialLoading}
            loading={status === statusType.IN_PROGRESS}
            data={data}
            total={total}
            filters={filters}
            handleFilterChange={this.handleFilterChange}

            handleDeleteSelection={this.handleRemoveActivities}
            history={history}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const ticker = getActiveTicker(get(state, 'profile.data'))

  return {
    activityForm: state.activity.activityForm,
    activityList: state.activity.activityList,
    token: state.token,
    participants: state.corporateParticipant.list.participants,
    securityId: ticker && ticker._security
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchActivities: bindActionCreators(getActivityList, dispatch),
  removeActivities: bindActionCreators(removeActivities, dispatch),
  resetActivity: bindActionCreators(resetActivity, dispatch),
  resetActivityList: bindActionCreators(resetActivityList, dispatch),
  fetchCorporateParticipants: bindActionCreators(fetchCorporateParticipants, dispatch),
  fetchContactFilter: bindActionCreators(fetchContactFilter, dispatch),
  resetContactSuggestions: bindActionCreators(resetContactSuggestions, dispatch),
  fetchInstitutionFilter: bindActionCreators(fetchInstitutionFilter, dispatch),
  resetInstitutionFilter: bindActionCreators(resetInstitutionFilter, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityGridContainer))
