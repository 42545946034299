import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { config } from '../../config'
import senchaRoutes from '../../data/routes/sencha.data'
import { reactToSenchaPath, senchaToReactPath } from '../../utils/navigate.util'
import { getActiveTicker, getEaIntegrationEnabledFlag, isFeatureEnabledByLocation } from '../../utils'
import { bindActionCreators } from 'redux'
import { notifySenchaInitialized, clearMessage } from '../../actions'
import { auth0Helper } from '../../services/auth0.service'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

/**
 * Sencha Component
 * Responsible for loading legacy sencha url's via iframe
 */
class SenchaContainer extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props)
        this.senchaRef = React.createRef()
    }

    /**
     * ComponentWillMount
     * Listen for hash change events from sencha
     */
    UNSAFE_componentWillMount = () => {
        window.addEventListener('message', this.onMessage, false)
    }

    /**
     * componentDidMount
     */
    async componentDidMount () {
      const senchaUrl = await this.buildSenchaUrl()
      this.redirectToSencha(senchaUrl)
    }

    /**
     * ComponentDidUpdate
     */
    async componentDidUpdate (prevProps) {
      const senchaUrl = await this.buildSenchaUrl()
      this.redirectToSencha(senchaUrl)

        // check for new post message request
        if (this.props.postMessage && this.props.postMessage !== prevProps.postMessage) {
            this.messageSencha(this.props.postMessage)
        }
    }

    /**
     * ComponentWillUnmount
     */
    componentWillUnmount = () => {
        window.removeEventListener('message', this.onMessage, false)
    }

    /**
     * On window message
     * @param e
     */
    onMessage = (e) => {
        const { source, type } = e.data

        if (source === 'sencha' && type === 'hashchange') {
            this.onSenchaHashChange(e)
        }

        if (source === 'sencha' && type === 'querystringchange') {
            this.onQueryStringChange(e)
        }

        if (source === 'sencha' && type === 'filestream') {
            this.onFileStream(e)
        }

        if (source === 'sencha' && type === 'initfinished') {
            this.props.notifySenchaInitialized()
        }
    }

    /**
     * Handle Sencha Query Change Events
     * @param e
     */
    onQueryStringChange = (e) => {
        const params = e && e.data && e.data.params
        const pathname = params && params.pathname
        const search = params && params.search

        if (!pathname || !search) {
            return
        }

        const { location, history, locationPathname } = this.props
        if ((pathname === locationPathname) && (search !== location.search)) {

            history.push({
                pathname,
                search
            })
        }
    }

    /**
     * Handle Sencha Hash Change Events
     * Gets react url from mapping, updating react route
     * @param event
     */
    onSenchaHashChange = (event) => {
        const { history } = this.props
        const data = event.data || {}
        const senchaPath = data.value // '#note/5ac50c83f7a64800041f99d0'
        const reactPath = senchaToReactPath(senchaPath) // '/activity/5ac50c83f7a64800041f99d0'

        if (reactPath && reactPath !== history.location.pathname) {
            history.push(reactPath, {
                fromSencha: true
            })
        }
    }

    /**
     * Handle Sencha File Streaming Events
     * Gets the query string parameters and opens a built URL for file streaming
     * @param event
     */
    onFileStream = (event) => {
        const { data } = this.props.token
        const { url, queryParams } = event.data || {}

        window.open(`${config.apiUrl}${url}?${queryString.stringify({ ...queryParams, token: data })}`, '_self')
    }

    /**
     * Determines if sencha view should be open in fullscreen
     * @returns {boolean}
     */
    isFullScreen = (senchaPath) => {
        const route = senchaRoutes.find((route) => route.senchaPath === senchaPath)
        return !!(route && route.fullscreen)
    }

    /**
     * Constructs url to sencha, includes access_token, security_id, senchaRoute and queryParams
     * @returns {Promise<string>}
     */
    buildSenchaUrl = async () => {
        const { location, securityId, token, locationPathname: path, auth0 } = this.props
        let userToken = token?.data

        if (auth0Helper.useAuth0SDK() && auth0.isAuthenticated) {
          userToken = await auth0.getAccessTokenSilently()
        }

        const senchaPath = reactToSenchaPath(path)
        const senchaRoute = senchaPath.replace('#', '') || 'idle'
        const qs = queryString.parse(location.search)
        const queryParams = senchaRoute !== 'idle' ? (qs.query ? '' : location.search) : ''

        let url = config.desktopUrl

        if (userToken && securityId) {
            url += `/?security_id=${securityId}`
            const hash = `#${senchaRoute}${queryParams}`

            // for prod env, don't authorize sencha via access_token in qs
            if (!['prod', 'uat', 'stage'].includes(process.env.REACT_APP_ENV)) {
                url += `&access_token=${userToken}`
            }

            url += hash
        }
        return url
    }

    /**
     * Updates iframe src without updating browser history which helps to avoid issues with back and forward buttons
     * @param url
     */
    redirectToSencha = (url) => {
        const iframe = document.getElementById('sencha-viewport')
        const { securityId, token } = this.props

        if (token && token.data && securityId && iframe) {
            // TODO NOT THIS
            iframe.contentWindow && iframe.contentWindow.location && iframe.contentWindow.location.replace(url)
        }
    }

    /**
     * Message Sencha
     * Sends posMessage to sencha window
     * @param message
     */
    messageSencha = (message) => {
        this.senchaRef.current.contentWindow.postMessage(message, config.desktopUrl)
    }

    /**
     * Render
     * @return {XML}
     */
    render () {
        const { location, auth, profile, visible, senchaFullscreenEnabled, locationPathname, auth0 } = this.props
        let senchaPath = reactToSenchaPath(locationPathname)

        if (
          isFeatureEnabledByLocation(profile, location) ||
          (location?.pathname.includes('/analytics') && getEaIntegrationEnabledFlag()) ||
          (location?.pathname.includes('/webcast') && getEaIntegrationEnabledFlag())
        ) {
          senchaPath = null
        }

        const active = !!senchaPath && visible

        if(auth0Helper.useAuth0SDK() && auth0.isLoading){
          return <></>
        }
        
        const userIsLoggedIn = (auth0Helper.useAuth0SDK() && auth0.isAuthenticated) || (!auth0Helper.useAuth0SDK() && auth.isLoggedIn())
        if (senchaPath && !userIsLoggedIn) {
          return <Redirect to={`/login#redirect=${locationPathname}`} />
          }

        const baseClassName = [
            'sencha-viewport',
            active ? 'sencha-viewport--active' : '',
            (this.isFullScreen(senchaPath) || senchaFullscreenEnabled) ? 'sencha-viewport--fullscreen' : ''
        ].join(' ')

        return (
            <div id={'sencha-container'} className={baseClassName}>
                <iframe
                    ref={this.senchaRef}
                    id='sencha-viewport'
                    title='sencha-viewport'
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const ticker = getActiveTicker(state.profile && state.profile.data)
    return {
        location: ownProps.location,
        profile: state.profile,
        token: state.token,
        securityId: ticker && ticker._security,
        senchaFullscreenEnabled: state.ui.fullscreen.senchaEnabled,
        postMessage: state.sencha && state.sencha.postMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    notifySenchaInitialized: bindActionCreators(notifySenchaInitialized, dispatch),
    clearPostMessage: bindActionCreators(clearMessage, dispatch)
})

export default withLDConsumer()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SenchaContainer)))
