import './all.container.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { debounce, get, uniq, throttle } from 'lodash'
import queryString from 'query-string'
// actions
import {
  closeModal,
  CREATE_TARGETS_FAILURE,
  createTargetingSavedSearch,
  createTargets,
  createToast,
  deleteTargetingSavedSearch,
  getPeers,
  getTargetingSavedSearch,
  modalType,
  openModal,
  setFlyoutDetailsItem,
  resetFlyoutDetailsItem,
  resetPeers,
  resetTargetingFilter,
  resetTargetingSavedSearch,
  resetTargetLocationSuggestions,
  resetTargetSecuritySuggestions,
  searchTargetLocationFactset,
  searchTargets,
  searchTargetSecurity,
  setTargetingFilterErrors,
  statusType,
  updateTargetingFilter,
  updateTargetingSavedSearch,
  createTarget,
  deleteTarget,
  CREATE_TARGET_FAILURE,
  REMOVE_TARGET_FAILURE
} from '../../../../actions'
// components
import TargetingGrid from './grid/grid.component'
import TargetingSidebar from './sidebar/sidebar.component'
import TargetingToolbar from './toolbar/toolbar.component'
import TargetDetail from './detail/detail.component'
import { Flyout } from '../../../../components'
// utils
import { allGridBulkActions, DEFAULT_TICKER, formatDate, getActiveTicker, getClassName, isSubscribed, TARGETING_URL } from '../../../../utils'
import { config } from '../../../../config'

import RouteLeavingPrompt from '../../../../components/routeLeavingPrompt/routeLeavingPrompt.component'
import { auth0Helper } from '../../../../services/auth0.service'

/**
 * All Targets Tab Container
 */
class AllContainer extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      sidebarCollapsed: false,
      flyoutCollapsed: true,
      flyoutLeftOffset: 500,
      layoutConfig: {
        flyoutMinWidth: 900,
        flyoutShadowWidth: 10,
        navigationWidth: 50
      },
      blockNavigation: false
    }
    this.handleFlyoutOpen = debounce(this.handleFlyoutOpen, 1000, { leading: true, trailing: true })
    this.handleGridResize = throttle(this.handleGridResize, 100)
  }

  /**
   * componentDidMount
   */
  componentDidMount () {
    const { resetPeers } = this.props
    resetPeers && resetPeers().then(() => {
      this.fetchTargets()
    })
  }

  /**
   * componentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const pageChanged = (prevProps.filter.page !== this.props.filter.page)
    if (pageChanged) {
      this.fetchTargets()
    }
  }

  /**
   * componentWillUnmount
   * Reset aiTargeting filter state
   */
  componentWillUnmount () {
    const { resetTargetingFilter, resetPeers } = this.props
    resetPeers && resetPeers()
    resetTargetingFilter && resetTargetingFilter()
  }

  /**
   * Dispatches an action to load targets
   * @param query
   */
  fetchTargets = (query) => {
    const { searchTargets, filter } = this.props
    const params = this.getQueryParams(query || filter)
    searchTargets(params)
  }

  /**
   * Get selected dropdown options
   * @param options
   * @returns {*}
   */
  getOptions = (options) => {
    return (options || []).filter((option) => option.selected).map((option) => option.value)
  }

  /**
   * Get query params from filter
   * @return {{type: *}}
   */
  getQueryParams = (filter) => {
    const { securityId, peers, tickerId } = this.props
    const {
      owns_security, location, target_type, quality_rating, aum, equity_aum, purchasing_power, investment_style,
      styleOptions, turnoverOptions, typeOptions, peers: filterPeers, peer_activity, exclude_activists, ai_only,
      logged_activity, activity_start, activity_end, page, limit
    } = filter

    const security = [].concat(owns_security || [], (peers || []).map((peer) => peer._security))

    return {
      location,
      target_type,
      quality_rating,
      aum,
      equity_aum,
      purchasing_power,
      investment_style,
      style: this.getOptions(styleOptions),
      turnover: this.getOptions(turnoverOptions),
      type: this.getOptions(typeOptions),
      owns_security: uniq(security.map((security) => security._id)),
      peers: filterPeers,
      peer_activity,
      exclude_activists,
      ai_only,
      logged_activity,
      activity_start: activity_start ? formatDate(activity_start, 'MM-DD-YYYY') : null,
      activity_end: activity_end ? formatDate(activity_end, 'MM-DD-YYYY') : null,
      page,
      limit,
      securityId,
      tickerId: tickerId || DEFAULT_TICKER
    }
  }

  /**
   * Search Metro Locations
   * @param query
   */
  searchLocations = (query) => {
    const { searchTargetLocationFactset } = this.props
    searchTargetLocationFactset(query)
  }

  /**
   * Search Ownership Securities
   * @param query
   */
  searchSecurities = (query) => {
    const { searchTargetSecurity } = this.props
    searchTargetSecurity(query)
  }

  /**
   * Dispatches an action to update filer state
   * @param filter
   */
  handleFilterChange = (filter) => {
    const { updateTargetingFilter } = this.props
    updateTargetingFilter(filter)
  }

  /**
   * Dispatches an action to load targeting saved search
   */
  fetchSavedSearch = () => {
    const { getSavedSearch } = this.props
    getSavedSearch && getSavedSearch()
  }

  /**
   * Sends request to download targeting csv file
   */
  handleExport = async () => {
    const { filter, token } = this.props

    const exportToken = auth0Helper.useAuth0SDK()
      ? await auth0Helper.getAccessTokenSilently()()
      : token?.data

    const query = {
      ...this.getQueryParams(filter),
      token: exportToken 
    }

    window.open(`${config.exportApiUrl}/targeting/search/export?${queryString.stringify(query)}`, '_self')
  }

  /**
   * Toggle Sidebar
   */
  toggleSidebar = () => {
    const { sidebarCollapsed } = this.state
    this.setState({
      sidebarCollapsed: !sidebarCollapsed,
      flyoutCollapsed: true
    })
  }

  /**
   * set blockNavigation flag
   */
  handelBlockNavigation = () => {
    this.setState({
      blockNavigation: true
    })
  }
  /**
   * Handles grid resize and updates flyout width
   * @param width
   */
  handleGridResize = (width) => {
    this.setState({
      flyoutLeftOffset: width
    })
  }

  /**
   * Handles AgGrid onRowClick
   * @param data
   */
  handleRowClick = (data) => {
    if (!data) {
      return
    }

    const { history } = this.props
    const { entityType, factset_fund_id, factset_entity_id } = data

    history.push(`/${entityType}/${factset_fund_id || factset_entity_id}`)
  }

  /**
   * Handle bulk actions based on action id
   * @param actionId
   * @param selectedRows
   */
  handleBulkAction = async (actionId, selectedRows) => {
    if (!actionId || !selectedRows || !selectedRows.length) {
      return
    }

    switch (actionId) {
      case allGridBulkActions.ADD_TO_SAVED_TARGETS:
        await this.handleAddToSavedTargets(selectedRows)
        break
      case allGridBulkActions.ADD_TO_BRIEFING_BOOK:
        this.handleAddToBriefingBook(selectedRows)
        break
      default:
        break
    }
  }

  /**
   * Add targets to save targets
   * @param selectedRows
   */
  handleAddToSavedTargets = async (selectedRows) => {
    if (!selectedRows || !selectedRows.length) {
      return
    }

    const result = await this.props.createTargets({
      targets: selectedRows
    })

    if (result.type === CREATE_TARGETS_FAILURE) {
      this.props.openModal({
        type: modalType.ERROR_MODAL
      })
    }
  }

  /**
   * open Prompt Modal
   * @param nextLocation
   * @param handleConfirmNavigationClick
   */
  handleOpenPromptModal = (nextLocation, handleConfirmNavigationClick) => {
    const { openModal, closeModal } = this.props
    openModal({
      type: modalType.CONFIRM_NAVIGATION_MODAL,
      props: {
        onClose: closeModal,
        handleCancel: closeModal,
        title: 'Discard Filtered Targets?',
        message: 'You are about to navigate away from your targeting search, are you sure you want to proceed?',
        handleConfirm: () => handleConfirmNavigationClick(nextLocation)
      }
    })
  }

  /**
   * Redirect btn click action
   * @param nextLocation
   */
  handleNavigationModalClick = (nextLocation, callback) => {
    if (!nextLocation) {
      return
    }
    const {  closeModal } = this.props
    callback()
    closeModal()
  }

  /**
   * Add targets to briefing book
   * @param selectedRows
   */
  handleAddToBriefingBook = (selectedRows) => {
    if (!selectedRows || !selectedRows.length) {
      return
    }

    this.props.openModal({
      type: modalType.ADD_TO_BRIEFING_BOOK_MODAL,
      props: {
        entities: selectedRows.map(({ entityType, q4_entity_id: entityId }) => ({
          entityType,
          entityId
        }))
      }
    })
  }

  /**
   * Handles flyout preview
   */
  handleFlyoutOpen = (data) => {
    const { setFlyoutDetailsItem } = this.props

    setFlyoutDetailsItem({
      entityId: data.entityId,
      entityType: data.entityType,
    })

    this.setState({
      flyoutCollapsed: false,
      sidebarCollapsed: true,
    })
  }

  /**
   * Closes flyout preview
   */
  handleFlyoutClose = () => {
    const { resetFlyoutDetailsItem } = this.props

    this.setState({
      flyoutCollapsed: true,
      flyoutDetailProps: null
    }, () => {
      resetFlyoutDetailsItem()
    })
  }

  /**
   * On Action Completion Failure display an error
   */
  handleFailure = () => {
    const { openModal } = this.props
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  /**
   * Click event for utility menu which will add/remove _target from flyout and grid
   * @param targetId
   * @param reference
   */
  handleTargetUtilAction = (targetId, reference) => {
    const { createTarget, deleteTarget, onClose } = this.props
    const action = targetId ? deleteTarget(targetId) : createTarget({ reference })

    action.then((data) => {
      if ([CREATE_TARGET_FAILURE, REMOVE_TARGET_FAILURE].includes(data.type)) {
        return this.handleFailure()
      }
      onClose && onClose()
    })
  }

  /**
   * Render Targeting Page
   * @returns {*}
   */
  render () {
    const {
      targets, total, status, filter, peers, getPeers, resetPeers, savedSearch, createSavedSearch, updateSavedSearch,
      deleteSavedSearch, resetSavedSearch, resetTargetLocationSuggestions, resetTargetSecuritySuggestions,
      resetTargetingFilter, aiTargetingSubscription, openModal, closeModal, createToast, region, setTargetingFilterErrors,
      isSearchLocationsLoading, isSearchSecuritiesLoading, flyoutDetailsHistory, history
    } = this.props
    const { sidebarCollapsed, flyoutCollapsed, flyoutLeftOffset, layoutConfig, blockNavigation } = this.state

    const noData = !targets || !targets.length
    const initLoading = status === statusType.IN_PROGRESS && noData
    const flyoutDetailsItem = (flyoutDetailsHistory.length && flyoutDetailsHistory[flyoutDetailsHistory.length - 1]) || null

    const classes = getClassName('targeting-page-old_tabs_tab targeting-page-old_tabs_tab--all', [
      { condition: !flyoutCollapsed, trueClassName: 'targeting-page-old_tabs_tab--flyout-open' }
    ])

    return (
      <div className={classes}>
        <RouteLeavingPrompt
          navigate={path => history.push(path)}
          page={TARGETING_URL}
          handleOpenPromptModal={this.handleOpenPromptModal}
          handleNavigationModalClick={this.handleNavigationModalClick}
          shouldBlockNavigation={(location) => ((history.location.state && history.location.state.blockNavigation) || blockNavigation) && location !== TARGETING_URL}
          />
        <TargetingSidebar
          collapsed={sidebarCollapsed}
          filter={filter}
          handelBlockNavigation={this.handelBlockNavigation}
          searchLocations={this.searchLocations}
          resetLocationSuggestions={resetTargetLocationSuggestions}
          isSearchLocationsLoading={isSearchLocationsLoading}
          searchSecurities={this.searchSecurities}
          resetSecuritySuggestions={resetTargetSecuritySuggestions}
          isSearchSecuritiesLoading={isSearchSecuritiesLoading}
          peers={peers}
          getPeers={getPeers}
          resetPeers={resetPeers}
          handleFilterChange={this.handleFilterChange}
          resetFilter={resetTargetingFilter}
          setErrors={setTargetingFilterErrors}
          fetchTargets={this.fetchTargets}
          savedSearch={savedSearch}
          fetchSavedSearch={this.fetchSavedSearch}
          createSavedSearch={createSavedSearch}
          updateSavedSearch={updateSavedSearch}
          deleteSavedSearch={deleteSavedSearch}
          resetSavedSearch={resetSavedSearch}
          aiTargetingSubscription={aiTargetingSubscription}
          region={region}
          openModal={openModal}
          closeModal={closeModal}
          createToast={createToast}
        />
        <div className='targeting-page-tab_body'>
          <TargetingToolbar
            collapsed={sidebarCollapsed}
            toggleSidebar={this.toggleSidebar}
            handleExport={this.handleExport}
            resultsTotalCount={total}
            noData={noData}
          />
          <TargetingGrid
            isLoading={status === statusType.IN_PROGRESS}
            isGridReact={!initLoading}
            aiTargetingSubscription={aiTargetingSubscription}
            region={region}
            data={targets}
            total={total}
            filter={filter}
            flyoutCollapsed={flyoutCollapsed}
            flyoutLeftOffset={flyoutLeftOffset}
            layoutConfig={layoutConfig}
            handleFilterChange={this.handleFilterChange}
            handleGridResize={this.handleGridResize}
            handleFlyoutOpen={this.handleFlyoutOpen}
            handleRowClick={this.handleRowClick}
            handleBulkAction={this.handleBulkAction}
            handleTargetUtilAction={this.handleTargetUtilAction}
          />
          <Flyout
            collapsed={flyoutCollapsed}
            minWidth={layoutConfig.flyoutMinWidth}
            leftOffset={flyoutLeftOffset}
            handleClose={this.handleFlyoutClose}
          >
            {flyoutDetailsItem &&
            <TargetDetail
              data={flyoutDetailsItem}
              handleClose={this.handleFlyoutClose}
              handleTargetUtilAction={this.handleTargetUtilAction}
            />
            }
          </Flyout>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile
  const ticker = getActiveTicker(profile.data)
  const securityId = ticker && ticker._security
  const tickerId = ticker && ticker.q4_ticker_id

  return {
    targets: state.targeting.search.data,
    total: state.targeting.search.total,
    status: state.targeting.search.status,
    filter: state.targeting.filter,
    peers: state.peer.data,
    savedSearch: state.targeting.savedSearch,
    isSearchLocationsLoading: state.targeting.filter.locationSuggestionStatus === statusType.IN_PROGRESS,
    isSearchSecuritiesLoading: state.targeting.filter.securitySuggestionStatus === statusType.IN_PROGRESS,
    aiTargetingSubscription: get(isSubscribed(profile.data, 'ai_targeting'), 'enabled') === true,
    enableFlyout: get(isSubscribed(profile.data, 'targeting_flyout'), 'enabled') === true,
    region: get(profile.data, 'region'),
    token: state.token,
    flyoutDetailsHistory: state.targeting.flyout.data,
    securityId,
    tickerId
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchTargets: bindActionCreators(searchTargets, dispatch),
  updateTargetingFilter: bindActionCreators(updateTargetingFilter, dispatch),
  searchTargetLocationFactset: bindActionCreators(searchTargetLocationFactset, dispatch),
  searchTargetSecurity: bindActionCreators(searchTargetSecurity, dispatch),
  resetTargetLocationSuggestions: bindActionCreators(resetTargetLocationSuggestions, dispatch),
  resetTargetSecuritySuggestions: bindActionCreators(resetTargetSecuritySuggestions, dispatch),
  getPeers: bindActionCreators(getPeers, dispatch),
  resetPeers: bindActionCreators(resetPeers, dispatch),
  resetTargetingFilter: bindActionCreators(resetTargetingFilter, dispatch),
  setTargetingFilterErrors: bindActionCreators(setTargetingFilterErrors, dispatch),
  getSavedSearch: bindActionCreators(getTargetingSavedSearch, dispatch),
  createSavedSearch: bindActionCreators(createTargetingSavedSearch, dispatch),
  updateSavedSearch: bindActionCreators(updateTargetingSavedSearch, dispatch),
  deleteSavedSearch: bindActionCreators(deleteTargetingSavedSearch, dispatch),
  resetSavedSearch: bindActionCreators(resetTargetingSavedSearch, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  createToast: bindActionCreators(createToast, dispatch),
  createTargets: bindActionCreators(createTargets, dispatch),
  setFlyoutDetailsItem: bindActionCreators(setFlyoutDetailsItem, dispatch),
  resetFlyoutDetailsItem: bindActionCreators(resetFlyoutDetailsItem, dispatch),
  createTarget: bindActionCreators(createTarget, dispatch),
  deleteTarget: bindActionCreators(deleteTarget, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllContainer))
