import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withAuth0 } from '@auth0/auth0-react'

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { userLogout } from '../../actions'
import { LOCAL_AUTH_MODE, USED_LOCAL_AUTH_MODE } from '../../services/auth0.service'
import { isLocalhostApi, removeLocalizationLocalStorage } from '../../utils'
import { ACCESS_TOKEN } from '../../services/auth.service'

class LogoutContainer extends Component {
  UNSAFE_componentWillMount () {
    removeLocalizationLocalStorage()

    // Remove Cookies
    const domain = !isLocalhostApi() ? 'domain=.q4desktop.com;' : ''
    document.cookie = `${ACCESS_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domain}`

    // Clear local authentication mode
    const usedLocalAuthMode = !!localStorage.getItem(USED_LOCAL_AUTH_MODE.KEY)
    if (usedLocalAuthMode) {
      localStorage.removeItem(LOCAL_AUTH_MODE.KEY)
      localStorage.removeItem(USED_LOCAL_AUTH_MODE.KEY)
    }

    // Clear auth
    if (this.props.auth) {
      this.props.auth.logout()

      // Clear user state
      this.props.userLogout()
    }
    if (this.props.auth0) {
      this.props.auth0.logout({ returnTo: window.location.origin })
    }
  }

  render () {
    // TODO: Remove this temp approach after full deprecation of Legacy Authentication
    // Currently required to force LaunchDarklyWrapper to 'reset' when using Legacy Login
    return (
      <>
        {
          this.props.auth0
            ? <></> // Auth0 redirect is managed by auth0.logout()
            : <>{window.location.href = '/login'}</>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  userLogout: bindActionCreators(userLogout, dispatch)
})

LogoutContainer.propTypes = {
  auth: PropTypes.object
}

export const LogoutContainerSelfAuth = withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutContainer))
export const LogoutContainerAuth0 = withRouter(connect(mapStateToProps, mapDispatchToProps)(withAuth0(LogoutContainer)))
