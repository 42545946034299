import { toLower } from 'lodash'

export function getMatches (string, regex, index) {
  (typeof index !== 'undefined') || (index = 1) // default to the first capturing group
  const matches = []
  let match = regex.exec(string)
  while (match) {
    matches.push(match[index])
    match = regex.exec(string)
  }
  return matches
}

/**
 * Clean titles for export, leaving only letters and numbers
 * @param value
 * @returns {string}
 */
export function formatTitle (value) {
  if (value && typeof value === 'string') {
    return value.trim().replace(/[^\w\s]/gi, '')
  }
}

export function formatPhone (phone) {
  if (!phone) {
    return
  }
  return phone
    .replace(/^([0-9]{1,3})?([0-9]{3})([0-9]{3})([0-9]{4})$/, '$1.$2.$3.$4')
    .split('.')
    .filter((group) => group)
    .join('.')
}

/**
 * Remove characters not escaped by encodeURI
 * when saving value that suppose to be a part of url later, e.g. tag
 * @param value
 * @returns {string}
 */
export function removeUnescapedCharacters (value) {
  if (value && typeof value === 'string') {
    return value.trim().replace(/[-_.!~*')(\s]/g, '')
  }
}

/**
 * Checks if an email address is valid i.e. if it follows _@_._ format
 * @param {string} email
 * @returns {boolean}
 */
export function isValidEmail (email) {
  if (email && typeof email === 'string') {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
  }
  return false
}

/**
 * Formats a string to be title case
 * @param {string} value
 * @returns {string}
 */
 export function formatTitleCase (value) {
  return toLower(value).replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}
