import React, { useState, useEffect } from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import Grid from '@material-ui/core/Grid'

// components
import { HtmlEditor } from '../../shared'
import { Modal, EntitySearch, RadioGroup, Select, TextField, DatePicker, Checkbox } from '../../index'

// utils
import { modalType } from '../../../actions/ui'
import { normalizeBody, toolbarOptions, ENTITY_TYPE, THEMES, getLocalizedFormat } from '../../../utils'
import { get, isNumber } from 'lodash'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  loading: PropTypes.bool,
  deal: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    stage: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    usePurchasingPower: PropTypes.bool,
    start: PropTypes.shape({
      date: PropTypes.string,
      timezone: PropTypes.string
    }),
    end: PropTypes.shape({
      date: PropTypes.string,
      timezone: PropTypes.string
    }),
    description: PropTypes.string,
    entity: PropTypes.arrayOf(PropTypes.shape({
      entityId: PropTypes.string,
      entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION])
    }))
  }),
  entities: PropTypes.array,
  pipeline: PropTypes.array.isRequired,
  isEurope: PropTypes.bool,
  purchasingPowerProgress: PropTypes.bool,
  purchasingPower: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onGetPurchasingPower: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  loading: false,
  deal: {},
  entities: [],
  pipeline: [],
  purchasingPowerProgress: false
}

const TYPE = [
  { dataId: 'ObjectiveNew', label: 'New', value: 'new' },
  { dataId: 'ObjectiveIncrease', label: 'Increase', value: 'increase' },
  { dataId: 'ObjectiveMaintain', label: 'Maintain', value: 'maintain' },
  { dataId: 'ObjectivePrevious', label: 'Previous', value: 'previous' }
]

/**
 * Get formatted pipeline options
 * @param pipeline
 * @returns {{label: *, value: *}[]}
 */
function getStages (pipeline) {
  return (pipeline || []).map((stage) => ({
    label: stage.label,
    value: stage.id
  }))
}

/**
 * Get formatted entities
 * @param entity
 * @returns {{entityType: *, entityId: *}[]}
 */
function getEntity (entity) {
  return (entity || []).map(({ entityId, entityType }) => ({ entityId, entityType }))
}

/**
 * Deal Create/Edit Component
 * @param props
 */
function EditModal (props) {
  const {
    dataId, loading, deal, pipeline, isEurope, purchasingPowerProgress, purchasingPower, onGetPurchasingPower, onClose,
    errors, values, setFieldValue, handleChange, handleSubmit, openModal
  } = props
  const { type, title, stage, amount, start = {}, end = {}, description, entity } = (values || {})
  const { id } = (deal || {})

  const stages = getStages(pipeline)
  const [usePurchasingPower, setUsePurchasingPower] = useState(!!values.usePurchasingPower)
  useEffect(() => {
    isNumber(purchasingPower) && setFieldValue('amount', purchasingPower)
  }, [purchasingPower, setFieldValue])

  /**
   * Handle stage change
   * @param option
   */
  const handleStageChange = (option) => {
    const { value } = (option || {})
    const lost = pipeline.find((stage) => stage.lost)

    id && lost && (lost.id === value) ? openModal({
      type: modalType.CONFIRM_MODAL,
      props: {
        content: {
          title: 'Remove from Pipeline?',
          message: `Changing the stage to 'Objective Not Met' will hide the Deal from the Investor Pipeline and all Potential Investor profiles.`,
          label: 'Remove'
        },
        onConfirm: () => {
          onClose({ type: modalType.CONFIRM_MODAL })
          setFieldValue('stage', value)
        }
      }
    }) : setFieldValue('stage', value)
  }

  /**
   * Handle date change
   * @param value
   * @param type
   */
  const handleDateChange = (value, type) => {
    const date = {
      date: (type === 'end')
        ? moment(value).endOf('day').format()
        : moment(value).startOf('day').format(),
      timezone: moment.tz.guess()
    }
    setFieldValue(type, date)
  }

  /**
   * Handle usePurchasingPower flag change
   * @param value
   */
  const handleUsePurchasingPowerChange = (value) => {
    value && onGetPurchasingPower(getEntity(entity))
    setUsePurchasingPower(value)
    setFieldValue('usePurchasingPower', value)
  }

  /**
   * Handle entity change
   * @param entity
   */
  const handleEntityChange = (entity) => {
    usePurchasingPower && onGetPurchasingPower(getEntity(entity))
    setFieldValue('entity', entity)
  }

  return (
    <Modal
      dataId={dataId}
      visible
      fullscreen
      disableRestoreFocus
      loading={loading}
      badge='q4i-deal-2pt'
      title={`${id ? 'Edit' : 'Create'} Deal`}
      footerButtons={[
        {
          dataId: `${dataId}Cancel`,
          label: 'Cancel',
          ui: 'shaded',
          onClick: onClose
        },
        {
          dataId: `${dataId}Save`,
          label: 'Save',
          ui: THEMES.CITRUS,
          onClick: handleSubmit
        }
      ]}
      onClose={onClose}
    >
      <Grid container spacing={3} className='fullscreen-modal-layout deal-edit-modal'>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RadioGroup
                dataId={`${dataId}Objective`}
                name='type'
                label='Objective'
                selected={type}
                controls={TYPE}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                dataId={`${dataId}Title`}
                name='title'
                label='Title'
                placeholder='Deal Title'
                value={title}
                error={{ isError: !!errors.title }}
                onChange={handleChange}
                autoFocus
                required
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                dataId={{
                  inputId: `${dataId}StageInput`,
                  menuId: `${dataId}StageMenu`
                }}
                label='Stage'
                options={stages}
                value={stages.find((item) => item.value === stage)}
                onChange={handleStageChange}
                searchable={false}
                clearable={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    dataId={`${dataId}PurchasingPower`}
                    name='amount'
                    type='number'
                    loading={purchasingPowerProgress}
                    value={isNumber(amount) ? Math.abs(amount) : ''}
                    label='Potential POS'
                    placeholder='00,000,000'
                    min={0}
                    max={Math.pow(2, 31) - 1}
                    disabled={usePurchasingPower}
                    onChange={handleChange}
                  />
                  {!isEurope && <Checkbox
                    dataId={`${dataId}UsePurchasingPower`}
                    name='usePurchasingPower'
                    label='Use Purchasing Power'
                    isSelected={usePurchasingPower}
                    onChange={handleUsePurchasingPowerChange}
                  />}
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    dataId={`${dataId}Date`}
                    label='Create'
                    format={getLocalizedFormat('MM/DD/YYYY')}
                    value={moment(start.date)}
                    max={moment()}
                    disabled={!!id}
                    onChange={(value) => handleDateChange(value, 'start')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    dataId={`${dataId}CloseDate`}
                    label='Est. Close'
                    format={getLocalizedFormat('MM/DD/YYYY')}
                    value={end.date && moment(end.date)}
                    min={moment(start.date)}
                    error={{ isError: !!(errors.end && errors.end.date) }}
                    onChange={(value) => handleDateChange(value, 'end')}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <HtmlEditor
                dataId={`${dataId}Notes`}
                label='Description'
                placeholder='ex. investor rationale, background information'
                html={description}
                toolbar={toolbarOptions}
                onChange={(description) => setFieldValue('description', normalizeBody(description.html))}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <EntitySearch
            label='Potential Investors '
            entities={entity}
            error={{ isError: !!errors.entity, message: errors.entity }}
            onChange={handleEntityChange}
            isRelativeInstitution
            isRelativeSearch
            required
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

EditModal.propTypes = propTypes
EditModal.defaultProps = defaultProps

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: (props) => {
    const { deal, entities, pipeline } = props
    const { type, title, stage, amount, usePurchasingPower, start = {}, end = {}, description, entity } = deal

    return {
      ...deal,
      type: type || 'new',
      title: title || '',
      stage: stage || get(pipeline, '[0].id'),
      amount,
      usePurchasingPower: usePurchasingPower || false,
      start: {
        date: start.date ? moment(start.date).format() : moment().startOf('day').format(),
        timezone: start.timezone || moment.tz.guess()
      },
      end: {
        date: end.date && moment(end.date).format(),
        timezone: end.timezone || moment.tz.guess()
      },
      description: description || '',
      entity: entity || entities,
      archive: false
    }
  },
  handleSubmit: (values, { props }) => {
    const { onSave } = props
    onSave && onSave({ ...values, entity: getEntity(values.entity) })
  },
  isInvestor: Yup.addMethod(Yup.array, 'isInvestor', function ({ match, message }) {
    return this.test('isInvestor', message, (entity) => !!(entity || []).filter(match).length)
  }),
  validationSchema: Yup.object().shape({
    title: Yup.string().trim().required(),
    end: Yup.object().shape({
      date: Yup.string().required(),
      time: Yup.string()
    }).required(),
    entity: Yup.array()
      .of(Yup.object().shape({
        entityType: Yup.string().required(),
        entityId: Yup.string().required(),
        institutionId: Yup.string()
      }))
      .isInvestor({
        match: (entity) => [FUND, INSTITUTION].includes(entity.entityType),
        message: 'Add an Institution or at least one Fund to your Potential Investors'
      })
  })
})(EditModal)
