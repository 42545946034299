import '../../security.doc'
import React, { useState, memo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// components
import CustomCell from './cell/cell.component'
import { AgGrid, ErrorMessage, LoadingState, NoContentMessage } from '../../../../../../components'

// utils
import {
  getDefaultColumnDef,
  getQuarterEndDates,
  preventDefaultRowClick,
  isReportingWindow,
  ENTITY_TYPE,
  formatDate
} from '../../../../../../utils'

import './table.component.scss'

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  quarters: PropTypes.number.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  holderType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  })
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  quarters: 4,
  listOptions: {}
}

const { FUND, INSTITUTION } = ENTITY_TYPE

/**
 * Get names column based on holderType
 * @param holderType
 */
function getNameColumn (holderType) {
  const source = (holderType === FUND) ? FUND : INSTITUTION
  return [{
    ...getDefaultColumnDef({ columnIndex: 0, type: 'text' }),
    field: `${source}Name`,
    headerName: 'Name',
    minWidth: 484,
    pinned: 'left',
    lockPinned: true
  }
  ]
}
/**
 * Get extra columns based on holderType
 * @param holderType
 */

function getExtaColumns (holderType) {
  const isHidden = (holderType === 'insider')
  const source = (holderType === FUND) ? FUND : INSTITUTION
  return [{
    // columnIndex 2 responsible for proper styling, can be any even value greater then 0
    ...getDefaultColumnDef({ columnIndex: 2, type: 'centered' }),
    headerName: 'Style',
    minWidth: 140,
    maxWidth: 140,
    children: [
      {
        ...getDefaultColumnDef({ columnIndex: 2, type: 'centered' }),
        headerName: '',
        field: `${source}Style`,
        minWidth: 140,
        maxWidth: 140,
        valueFormatter: ({ value, data }) => {
          const { isNested, fundStyle } = (data || {})
          return isNested ? fundStyle : value
        },
        hide: isHidden
      }]
  }, {
    ...getDefaultColumnDef({ columnIndex: 1, type: 'centered' }),
    headerName: 'Turnover',
    minWidth: 140,
    maxWidth: 140,
    children: [
      {
        ...getDefaultColumnDef({ columnIndex: 1, type: 'centered' }),
        headerName: '',
        field: `${source}Turnover`,
        minWidth: 140,
        maxWidth: 140,
        valueFormatter: ({ value, data }) => {
          const { isNested, fundTurnover } = (data || {})
          return isNested ? fundTurnover : value
        },
        hide: isHidden
      }]
  }]
}

/**
 * Get Column Change Definition
 * @param columnIndex
 * @param type
 */
const getColumnChangeDefinition = (columnIndex, type) => {
  const columnDef = getDefaultColumnDef({ columnIndex, type })

  columnDef.cellClassRules['ag-cell-value--increase'] = ({ value }) => isReportingWindow() && value === 0 ? false : value > 0
  columnDef.cellClassRules['ag-cell-value--decrease'] = ({ value }) => isReportingWindow() && value === 0 ? false : value < 0

  return columnDef
}

/**
 * Get quarter column definition based on number of quarters selected
 * @param {HistoricalTableProps['quarters']} quarters
 * @return {{}}
 */
const getQuarterColumnDefinition = (quarters) => {
  const dates = getQuarterEndDates(quarters, moment.utc())

  return (dates || []).map((quarter, idx) => {
    const index = idx + 1
    return {
      ...getDefaultColumnDef({ columnIndex: idx, type: 'date' }),
      headerName: `${formatDate(quarter, undefined, undefined, true)}`,
      colId: `${quarter}`,
      minWidth: 280,
      maxWidth: 280,
      children: [
        {
          ...getDefaultColumnDef({ columnIndex: 0, type: 'number' }),
          headerName: 'Position',
          field: `q${index}Value`,
          colId: `q${index}Value`,
          lockPosition: true,
          minWidth: 140,
          maxWidth: 140
        },
        {
          ...getColumnChangeDefinition(1, 'change'),
          headerName: 'Change',
          field: `q${index}Change`,
          colId: `q${index}Change`,
          lockPosition: true,
          minWidth: 140,
          maxWidth: 140
        }
      ]
    }
  })
}

/**
 * Historical Holder Table Component
 * @typedef { import('./table.doc').HistoricalTableProps } HistoricalTableProps
 *
 * @param {HistoricalTableProps} props
 * @returns {JSX.Element}
 */
function HistoricalHolderTable (props) {
  const { pageSizeId, loading, data, error, total, listOptions, quarters, holderType, history, onQueryChange } = props
  const [agGrid, setAgGrid] = useState({})
  const [quarterColumns, setQuarterColumns] = useState([])

  const nameColumn = getNameColumn(holderType)
  const extraColumns = getExtaColumns(holderType)

  /**
   * Handle reset AgGrid sorting
   */
  const resetAgGridSorting = useCallback(() => {
    setTimeout(() => agGrid.columnApi && agGrid.columnApi.resetColumnState(), 0)
  }, [agGrid.columnApi])

  /**
   * Update columns on quarters change
   */
  useEffect(() => {
    quarters && setQuarterColumns(getQuarterColumnDefinition(quarters))
    resetAgGridSorting()
  }, [quarters, resetAgGridSorting])

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param {SecurityTableRowClickEvent} event
   * @returns {void}
   */
  const handleRowClick = ({ fundId, institutionId, holderType, holdingType }) => {
    if (holdingType === 'stakes') return

    const id = (holderType === FUND) ? fundId : institutionId || null
    id && history && history.push(`/${holderType}/${id}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: sortModel[0]?.colId,
        sortDir: sortModel[0]?.sort,
        page: 1
      }
    })
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      <LoadingState
        loading={loading}
        count={data?.length}
        error={error}
        emptyState={<NoContentMessage key='historical-table_error-state' />}
        errorState={(
          <ErrorMessage
            key='historical-table_error-state'
          />
        )}
        loadedState={(
          <AgGrid
            key='historical-table_loaded-state'
            domLayout='autoHeight'
            sizeToFit
            // suppress configs
            suppressMovableColumns
            suppressContextMenu
            // columns and data
            defaultColDef={{
              suppressMenu: true,
              sortable: true,
              cellRenderer: 'CustomCellRender'
            }}
            columnDefs={[
              ...nameColumn,
              ...quarterColumns,
              ...extraColumns
            ]}
            rowData={data}
            // pagination
            pagination
            paginationProps={{
              pageSizeId,
              forcePage: listOptions.page,
              initialPageSize: listOptions.limit,
              showPageSizeSelection: true,
              total,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange
            }}
            // custom components
            frameworkComponents={{
              CustomCellRender: renderCustomCell
            }}
            // event listeners
            onGridReady={handleGridReady}
            onGridSizeChanged={handleGridResize}
            onSortChanged={handleSortChange}
            onRowClicked={preventDefaultRowClick(
              handleRowClick, ['']
            )}
            isPinned={data.length}
          />
        )}
      />
    </div>
  )
}

HistoricalHolderTable.propTypes = propTypes
HistoricalHolderTable.defaultProps = defaultProps

export default memo(HistoricalHolderTable)
