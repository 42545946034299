import React, { memo, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import qs from 'querystring'
import moment from 'moment-timezone'
import ReactGA from 'react-ga'
import { get, isEmpty } from 'lodash'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { posthog } from "posthog-js";
import { config } from '../config'

// Services
import AuthService, { ACCESS_TOKEN } from '../services/auth.service'
import SenchaListenerService from '../services/senchaListener.service'
import PusherService from '../services/pusher.service'
import { auth0Helper, LOCAL_AUTH_MODE, USED_LOCAL_AUTH_MODE } from '../services/auth0.service'
import { LD_FEATURE_FLAGS, launchDarklyHelper, EA_INTEGRATION_PHASES } from './../services/launchDarkly.service'

// Actions
import {
  acknowledgeTerms,
  appendNewNotification,
  clearQuickSearch,
  createToast,
  deleteToast,
  downloadFile,
  getNotifications,
  getProfile,
  getQuickSearch,
  getStock,
  markReadNotifications,
  openModal,
  sendMail,
  setActiveTicker,
  setAppFullscreen,
  setToken,
  statusType
} from '../actions'

// Utils
import {
  getActiveTicker,
  getDaysUntilTrialExpiry,
  getEaPhase3EnabledFlag,
  isAcknowledgedTrial,
  isFeatureEnabledByLocation,
  isOrganizationChanged,
  isTrialExpired,
  isSubscribed,
  isLocalhostApi,
  reactToSenchaPath,
  reactToCrmEmailPath,
  buildLDProfile,
  canShowSplashScreen,
  LEGACY_ROUTE_PATH,
  ROUTE_PATH,
  TRIAL_DAYS_UNTIL_EXPIRY,
  SENCHA_ROUTE,
  CRM_EMAIL_ROUTE,
  CRM_MANAGE_EMAIL_ROUTE,
  getLaunchDarklyFlag,
  getProfileLocalizedCurrency,
  LOCALIZATION_KEY,
  isLocalhost,
  setLocalizationLocalStorage
} from '../utils'
import history from '../utils/history'
import { setAuthModeSSO } from '../utils/auth/auth.util'

// Pages
import ActivityLandingPage from '../pages/activity/landing/landing.page'
import ActivityDetailPage from './activity/detail/detail.page'
import BriefingBookPage from '../pages/briefingBook/landing/landing.page'
import BriefingBookDetailPage from '../pages/briefingBook/detail/detail.page'
import ContactLandingPage from '../pages/contact/landing/landing.page'
import ContactDetailPage from '../pages/contact/detail/detail.page'
import CorporateParticipantPage from '../pages/contact/participant/participant.page'
import DashboardContainer from '../containers/dashboard/dashboard.container'
import DashboardContainerConfig from '../containers/dashboard/edit/dashboardEdit.container'
import DealDetailPage from './pipeline/detail/detail.page'
import ErrorPage from '../pages/error/error.page'
import EstimatesContainer from '../containers/estimates/estimates.container'
import EngagementPage from '../pages/engagement/engagement.page'
import EventsTranscripts from '../pages/eventsTranscripts/landing/landing.page'
import FundPagePreload from '../pages/fund/fund.preload'
import InstitutionPagePreload from '../pages/institution/institution.preload'
import LoginContainer from '../containers/login/login.container'
import { LogoutContainerSelfAuth, LogoutContainerAuth0 } from '../containers/logout/logout.container'
import PeerListLandingPage from './peerList/landing/landing.page'
import PipelineContainer from '../containers/pipeline/pipeline.container'
import ReportBuilderContainer from '../containers/report/builder/reportBuilder.container'
import ReportContainer from '../containers/report/report.container'
import SearchPage from '../pages/search/search.page'
import SecurityPagePreload from '../pages/security/security.preload'
import SenchaPreload from '../containers/sencha/sencha.preload'
import EmailComposerContainer from '../containers/emailComposer/emailComposer.container'
import EmailConnectionManagerContainer from '../containers/emailConnectionManager/emailConnectionManager.container'
import TargetingPage from './targeting/targeting.page'
import TargetingOldPage from './targetingOld/targetingOld.page'
import PasswordResetPage from '../pages/passwordReset/passwordReset.page'
import WidgetContainer from '../containers/widget/widget.container'

// Components
import {
  Header,
  ModalProvider,
  Navigation,
  SplashScreen,
  Toast,
  TrialAgreementModal,
  TrialExpiredModal,
  TrialReminderModal,
  Footer,
  TryEngagementAnalytics
} from '../components'

import { useDispatchAction, useTypedSelector, usePrevious } from '../hook'

import './index.scss'

// Create shared auth instance
let auth

// Validate if user is authorized to access private routes
const PrivateRouteComponent = ({ component: Component, ...rest }) => {
  const {
    isLoading: auth0IsLoading,
    isAuthenticated: auth0IsAuthenticated,
    error: auth0Error
  } = auth0Helper.useAuth0SDK()
    ? useAuth0()
    : {}

  const component = auth0Helper.useAuth0SDK()
    ? (
      <Route
        {...rest}
        render={(props) => {
          const { match } = props
          const path = match && match.path
          const isFooter = !['/dashboard', '/builder/:id'].includes(path)

          if (auth0IsAuthenticated) {
            const localAuthMode = !!localStorage.getItem(LOCAL_AUTH_MODE.KEY)
            if (localAuthMode) localStorage.setItem(USED_LOCAL_AUTH_MODE.KEY, true)

            return (
              <>
                <div id='app-scrollable' className='app-scrollable'>
                  <div className='app-page-content'>
                    <Component {...props} />
                  </div>
                  {isFooter ? <Footer /> : null}
                </div>
              </>
            )
          } else if (!auth0IsLoading && !auth0IsAuthenticated && !auth0Error) {
            // Temp fix for logouts from external pages
            window.location.href = '/login'
            return <Redirect to={'/login'} />
          }
        }}
      />
      )
    : (
      <Route
        {...rest}
        render={(props) => {
          const { match } = props
          const path = match && match.path
          const isFooter = !['/dashboard', '/builder/:id'].includes(path)

          if (auth?.isLoggedIn()) {
            return (
              <>
                <div id='app-scrollable' className='app-scrollable'>
                  <div className='app-page-content'>
                    <Component {...props} />
                  </div>
                  {isFooter ? <Footer /> : null}
                </div>
              </>
            )
          } else if (auth && !auth.isLoggedIn()) {
            return <Redirect to={`/login#redirect=${path || '/dashboard'}`} />
          }
        }}
      />
      )

  return component
}

PrivateRouteComponent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  match: PropTypes.object
}
const PrivateRoute = memo(PrivateRouteComponent)

/**
 * Root Page
 * @param props
 * @returns {JSX.Element}
 */
function Root (props) {
  // States
  const [navigationExpanded, setNavigationExpanded] = useState(false)
  const [googleAnalyticsInitialized, setGoogleAnalyticsInitialized] = useState(false)
  const [posthogInitialized, setPosthogInitialized] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(auth)
  const [ldProfileInitiated, setLdProfileInitiated] = useState(false)
  const [isLocalizationCookieAvailable, setIsLocalizationCookieAvailable] = useState(false)

  // Dispatches
  const dispatch = useDispatch()
  const handleOpenModal = useDispatchAction(openModal, dispatch)
  const handleGetStock = useDispatchAction(getStock, dispatch)
  const handleGetProfile = useDispatchAction(getProfile, dispatch)
  const handleSetActiveTicker = useDispatchAction(setActiveTicker, dispatch)
  const handleClearQuickSearch = useDispatchAction(clearQuickSearch, dispatch)
  const handleGetQuickSearch = useDispatchAction(getQuickSearch, dispatch)
  const handleGetNotification = useDispatchAction(getNotifications, dispatch)
  const handleAppendNewNotification = useDispatchAction(appendNewNotification, dispatch)
  const handleMarkReadNotifications = useDispatchAction(markReadNotifications, dispatch)
  const handleDownloadFile = useDispatchAction(downloadFile, dispatch)
  const handleSendMail = useDispatchAction(sendMail, dispatch)
  const handleCreateToast = useDispatchAction(createToast, dispatch)
  const handleDeleteToast = useDispatchAction(deleteToast, dispatch)
  const handleSetToken = useDispatchAction(setToken, dispatch)
  const handleSetAppFullscreen = useDispatchAction(setAppFullscreen, dispatch)
  const handleAcknowledgeTerms = useDispatchAction(acknowledgeTerms, dispatch)

  // Retrieve data from store
  const profile = useTypedSelector((state) => state.profile)
  const stock = useTypedSelector((state) => state.stock.quote)
  const shared = useTypedSelector((state) => state.shared)
  const suggestions = useTypedSelector((state) => state.search.quick)
  const notifications = useTypedSelector((state) => state.notifications)
  const toast = useTypedSelector((state) => state.toast)
  const token = useTypedSelector((state) => state.token)
  const fullscreenEnabled = useTypedSelector((state) => state.ui.fullscreen.enabled)

  const ticker = getActiveTicker(profile?.data)
  const securityId = ticker && ticker._security
  const tickerId = ticker && ticker.q4_ticker_id

  const prevProfile = usePrevious(profile)
  const prevToken = usePrevious(token)

  const {
    getAccessTokenSilently,
    isLoading: auth0IsLoading,
    isAuthenticated: auth0IsAuthenticated,
    error: auth0Error
  } = useAuth0()
  const { ldClient } = props

  /**
   * ComponentDidMount
   */
  useEffect(() => {
    // logout user when Auth method switched mid way
    if (token.data?.length && auth0Helper.useAuth0SDK()) {
      history.push('/logout')
    }
    const { store } = props
    const profileLoaded = profile && profile.data && !profile.data._id

    // Initialize AuthService
    if (!auth0Helper.useAuth0SDK()) {
      auth = new AuthService({
        onTokenChange: (token, data) => {
          handleSetToken(token)
          token && handleGetProfile()

          // Refresh Stock Quote everytime token is changed
          if (
            data && data.profile &&
            data.profile._organization &&
            data.profile._organization.tickers &&
            data.profile._organization.tickers.length
          ) {
            const primaryTicker = data.profile._organization.tickers.find((t) => t.primary)
            handleGetStock(primaryTicker && primaryTicker.q4_ticker_id)
          }
        }
      })

      setIsLoggedIn(auth.isLoggedIn())

      if (auth.isLoggedIn() && !profileLoaded) {
        handleGetProfile()
      }
    } else {
      auth0Helper.setAccessTokenSilently(getAccessTokenSilently)
    }

    // Add global document listeners
    window.addEventListener('keydown', handleKeyDown)

    // Listen for sencha messages
    // eslint-disable-next-line no-new
    new SenchaListenerService({ history, store })

    // ComponentWillUnmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  /**
   * ComponentDidUpdate
   */
  useEffect(() => {
    if (auth0Helper.useAuth0SDK()) {
      if (
        profile?.status === statusType.SUCCESS &&
        prevProfile?.status !== statusType.SUCCESS
      ) {
        onProfileLoaded(profile.data)
        initPusher(profile.data._id, null, auth0Helper.useAuth0SDK())

        // Initialize launchdarkly profile
        const ldUser = buildLDProfile(profile.data)
        ldClient.identify(ldUser)
        setLdProfileInitiated(true)
      }

      setIsLoggedIn(auth0IsAuthenticated)
    } else {
      if (
        profile?.status === statusType.SUCCESS &&
        prevProfile?.status !== statusType.SUCCESS
      ) {
        onProfileLoaded(profile.data)

        const profileChanged = get(profile, 'data._id') !== get(prevProfile, 'data._id')
        const tokenChanged = get(token, 'data') !== get(prevToken, 'data')
        const profileId = profile && profile.data && profile.data._id
        const refreshPusher = (
          profileId &&
          token &&
          token.data &&
          (profileChanged || tokenChanged)
        )

        if (refreshPusher) {
          initPusher(profileId, token)
        }

        // Initialize launchdarkly profile
        const ldUser = buildLDProfile(profile.data)
        ldClient.identify(ldUser)
        setLdProfileInitiated(true)
      }

      setIsLoggedIn(auth.isLoggedIn())
    }
  }, [profile, token])

  useEffect(() => {
    if (auth0Helper.useAuth0SDK() && !auth0IsLoading) {
      const profileLoaded = profile && profile.data && profile.data._id
      if (auth0IsAuthenticated && !profileLoaded) {
        handleGetProfile()
      }
    }
  }, [auth0IsLoading])

  /**
   * Handle global keydown events
   * @param e
   */
  const handleKeyDown = (e) => {
    const event = e || window.event

    if (event.key === 'Escape' || event.key === 'Esc') {
      fullscreenEnabled && handleSetAppFullscreen && handleSetAppFullscreen(false)
    }
  }

  /**
   * Profile Loaded
   * Initialize pusher and get notifications only after profile loaded
   * @param profile
   */
  const onProfileLoaded = async (profile) => {
    const domain = !isLocalhostApi() ? 'domain=.q4desktop.com;' : ''

    initGoogleAnalytics(profile)
    initPosthog(profile)

    setLocalizationLocalStorage({ 
      region: profile?.region,
      currency: getProfileLocalizedCurrency(profile) 
    })

    document.cookie = `${LOCALIZATION_KEY}=${JSON.stringify({
      region: profile?.region,
      useLocalizedDateFormat: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_LOCALIZED_DATE_FORMAT)
    })}; path=/; ${domain}`

    setTimeout(() => {
      setIsLocalizationCookieAvailable(true)
    }, 800)

    if (auth0Helper.useAuth0SDK()){
      const token = await auth0Helper.getAccessTokenSilently()()
      document.cookie = `${ACCESS_TOKEN}=${token};${domain}`
    }

    handleGetNotification()
  }

  /**
   * Expand/Collapse Navigation
   * @param _event
   * @param expand - true/false to override
   */
  const onNavigationToggle = (_event, expand) => {
    const toggleTo = (expand !== undefined) ? expand : !navigationExpanded
    setNavigationExpanded(toggleTo)
  }

  /**
   * Returns either TrialAgreementModal, TrialExpiredModal, TrialReminderModal or nothing.
   * based on the state of a user profile.
   * @param profile
   */
  const getTrialModal = (profile) => {
    if (!profile || !profile.data || !profile.data._id) {
      return
    }

    if (!isAcknowledgedTrial(profile.data)) {
      return (
        <TrialAgreementModal
          acknowledgeTerms={handleAcknowledgeTerms}
          history={history}
        />
      )
    }

    if (isTrialExpired(profile.data)) {
      return (
        <TrialExpiredModal
          sendMail={handleSendMail}
          history={history}
          createToast={handleCreateToast}
        />
      )
    }

    const daysUntilTrialExpiry = getDaysUntilTrialExpiry(profile.data)
    const storedDaysUntilExpiry = parseInt(window.localStorage.getItem(TRIAL_DAYS_UNTIL_EXPIRY), 10)

    if (
      daysUntilTrialExpiry <= 7 &&
      storedDaysUntilExpiry !== daysUntilTrialExpiry
    ) {
      return (
        <TrialReminderModal
          daysUntilExpiry={daysUntilTrialExpiry}
          profile={profile.data}
          onClose={() => window.localStorage.setItem(TRIAL_DAYS_UNTIL_EXPIRY, daysUntilTrialExpiry)}
          sendMail={handleSendMail}
          createToast={handleCreateToast}
        />
      )
    }
  }

  /**
   * Initialize pusher
   * @param profileId
   * @param token
   */
  const initPusher = async (profileId, token, auth0 = false) => {
    // eslint-disable-next-line no-new
    new PusherService({
      token: auth0 ? await getAccessTokenSilently() : token && token.data,
      profileId,
      onNewNotification: handleAppendNewNotification
    })
  }

  /**
   * Init Google Analytics
   */
  const initGoogleAnalytics = (profile) => {
    if (!profile && googleAnalyticsInitialized) {
      return
    }

    // don't initialize GA on localhost - it will just throw CORS error
    if(isLocalhost()) {
      return
    }

    const type = profile.type
    let gaOptions = {}

    setGoogleAnalyticsInitialized(true)

    if (type && type.name && type.name !== 'internal') {
      gaOptions = {
        appName: 'Q4 Desktop',
        dimension1: profile.user,
        dimension2: profile._organization && profile._organization._id,
        dimension3: (type && type.name),
        dimension4: (type && type.expire) || ''
      }
    }

    ReactGA.initialize(config.googleAnalytics, {
      debug: process.env.REACT_APP_ENV === 'develop' ,
      gaOptions
    })

    ReactGA.pageview(history.location.pathname)
    history.listen((location, action) => ReactGA.pageview(location.pathname))
  }

  /**
   * Init Posthog Tracking
   * @param profile
   */
  const initPosthog = (profile) => {
    if (posthogInitialized) return

    try {
      const { _id, _organization, user, firstName, lastName, title, services, type, region, suspended, demo } = profile || {}
      const primaryTicker = _organization && _organization.tickers && Array.isArray(_organization.tickers) && _organization.tickers.find((t) => t.primary)
      const trialExpiration = type && type.name === 'trial' && type.expire ? moment(type.expire).utc().format('ddd MMM DD YYYY') : null

      posthog.init(config.analytics.postHog.key, {
        api_host: config.analytics.postHog.apiHost,
        disable_persistence: true,
        loaded: function (posthog) {
          posthog.identify(_id, {
            userId: _id,
            email: user,
            userFirstName: firstName,
            userLastName: lastName,
            userTitle: title,
            userType: type?.name,
            userRegion: region,
            userSuspended: suspended,
            userDemo: demo,
            userTrialExpiration: trialExpiration,
            ...(services || []).reduce((acc, service) => {
              acc['userService' + service?.type] = service?.enabled
              return acc
            }, {}),
            orgId: _organization?._id,
            orgName: _organization?.name,
            orgCapGroup: primaryTicker?.cap_group,
            orgCountry: primaryTicker?.country,
            orgCountryName: primaryTicker?.country_name,
            orgIndustry: primaryTicker?.industry,
            orgSector: primaryTicker?.sector,
          })
        },
      })
    } catch (e) {
      console.error('PostHog failed to initialize: ', e);
    }

    setPosthogInitialized(true)
  }

  /**
   * Get report builder routes based on user subscription
   * @return {*[]}
   */
  const getReportBuilderRoutes = () => [
    <PrivateRoute key='builder' exact path={ROUTE_PATH.REPORT_BUILDER} component={ReportContainer} />,
    <PrivateRoute key='builderId' exact path={ROUTE_PATH.REPORT_BUILDER_WITH_ID} component={ReportBuilderContainer} />,
    <PrivateRoute key='report' exact path={LEGACY_ROUTE_PATH.REPORT_BUILDER} component={() => <Redirect to={ROUTE_PATH.REPORT_BUILDER} />} />,
    <PrivateRoute key='reportId' exact path={LEGACY_ROUTE_PATH.REPORT_BUILDER_WITH_ID} component={() => <Redirect to={ROUTE_PATH.REPORT_BUILDER} />} />
  ]

  /**
   * Get all Sencha routes available
   * @return {*[]}
   */
  const getSenchaRoutes = () => {
    const routes = Object.values(SENCHA_ROUTE)
    return routes.map((route) => <Route key={route} exact path={route} />)
  }

  /**
   * Get all CRM Email routes available
   * @return {*[]}
   */
  const getCrmEmailRoutes = () => {
    const routes = Object.values(CRM_EMAIL_ROUTE)
    return routes.map((route) => <Route key={route} exact path={route} />)
  }

  const getCrmManageEmailRoutes = () => {
    const routes = Object.values(CRM_MANAGE_EMAIL_ROUTE)
    return routes.map((route) => <Route key={route} exact path={route} />)
  }

  /**
   * General Helpers
   */
  const profileLoaded = !!(profile?.data?._id)
  const profileFailed = profile && (
    (profile.status === statusType.ERROR) ||
    (profile.status === statusType.SUCCESS && isEmpty(profile.data))
  )
  const showSplash = canShowSplashScreen(
    auth0Helper.useAuth0SDK()
      ? !!auth0IsAuthenticated
      : isLoggedIn,
    profileLoaded,
    auth0Helper.useAuth0SDK(),
    auth0IsLoading,
    !!auth0Error,
    ldProfileInitiated,
    isLocalizationCookieAvailable
  )

  const isTrialUserLoggedIn = Boolean(
    profileLoaded &&
    profile.data.type &&
    profile.data.type.name === 'trial' &&
    isLoggedIn
  )
  const isSenchaRoute = !!reactToSenchaPath(history.location.pathname)
  const isCrmEmailRoute = !!reactToCrmEmailPath(history.location.pathname)
  const organizationChanged = isOrganizationChanged(profile.data)
  const queryParams = qs.parse(history.location.search.replace(/^\?*/, ''))
  const headlessMode = (get(queryParams, 'headless') === 'true')
  const rootClassName = [
    'root-container',
    navigationExpanded ? 'root-container--nav-expanded' : '',
    fullscreenEnabled ? 'root-container--fullscreen-enabled' : '',
    headlessMode ? 'root-container--headless-mode' : ''
  ].join(' ')

  // Custom routes behavior
  const reportBuilderRoutes = getReportBuilderRoutes()
  const senchaRoutes = getSenchaRoutes()
  const crmEmailRoutes = getCrmEmailRoutes()
  const crmManageEmailRoute = getCrmManageEmailRoutes()

  const TargetingComponent = isFeatureEnabledByLocation(profile, history.location)
    ? TargetingPage
    : TargetingOldPage
  const DynamicTryEAPage = [
    EA_INTEGRATION_PHASES.PHASE_2,
    EA_INTEGRATION_PHASES.PHASE_3
  ].includes(getLaunchDarklyFlag(LD_FEATURE_FLAGS.EA_INTEGRATION))
    ? TryEngagementAnalytics
    : () => senchaRoutes

  const getAnalyticPath = (pageComponent) => getEaPhase3EnabledFlag()
    ? EngagementPage
    : pageComponent

  const webAnalyticsRoutes = [
    <PrivateRoute key='analytics' exact path={ROUTE_PATH.ANALYTICS} component={getAnalyticPath(DynamicTryEAPage)} />,
    <PrivateRoute key='analyticsWithDays' exact path={ROUTE_PATH.ANALYTICS_WITH_DAYS} component={getAnalyticPath(DynamicTryEAPage)} />,
    <PrivateRoute key='analyticsWithDaysAndId' exact path={ROUTE_PATH.ANALYTICS_WITH_DAYS_AND_ID} component={getAnalyticPath(DynamicTryEAPage)} />
  ]

  const webCastAnalyticsRoutes = [
    <PrivateRoute key='webcast' exact path={ROUTE_PATH.WEBCAST} component={getAnalyticPath(DynamicTryEAPage)} />,
    <PrivateRoute key='webcastWithId' exact path={ROUTE_PATH.WEBCAST_WITH_ID} component={getAnalyticPath(DynamicTryEAPage)} />,
  ]

  const getLogoutContainer = (auth) => auth0Helper.useAuth0SDK()
    ? <LogoutContainerAuth0 auth={auth} />
    : <LogoutContainerSelfAuth auth={auth} />

  return (
    <div className={rootClassName}>
      {(
        profileFailed ||
        organizationChanged ||
        ( // In case of Auth0 Error (IE: Suspended Desktop accounts)
          auth0Helper.useAuth0SDK() &&
          !auth0IsLoading &&
          !auth0IsAuthenticated &&
          auth0Error
        )
      ) ? getLogoutContainer(auth) : null}

      {/* Local Authentication Mode Switch */}
      <Route exact path={ROUTE_PATH.USE_SSO_LOGIN} component={() => setAuthModeSSO(true, getLogoutContainer, auth)} />
      <Route exact path={ROUTE_PATH.USE_LEGACY_LOGIN} component={() => setAuthModeSSO(false, getLogoutContainer, auth)} />

      {showSplash
        ? <SplashScreen />
        : (
          <>
            {isTrialUserLoggedIn ? getTrialModal(profile) : null}

            {!headlessMode && <Navigation
              onNavigationToggle={onNavigationToggle}
              navigationExpanded={navigationExpanded}
              securityId={securityId}
              tickerId={tickerId}
              profile={profile}
            />}
            <div className='app-body'>
              {!headlessMode && <Header
                history={history}
                profile={profile}
                stock={stock}
                getStock={handleGetStock}
                securityId={securityId}
                tickerId={tickerId}
                setActiveTicker={handleSetActiveTicker}
                shared={shared}
                suggestions={suggestions}
                getQuickSearch={handleGetQuickSearch}
                clearQuickSearch={handleClearQuickSearch}
                notifications={notifications}
                markReadNotifications={handleMarkReadNotifications}
                downloadFile={handleDownloadFile}
                sendMail={handleSendMail}
                createToast={handleCreateToast}
                auth={auth}
                openModal={handleOpenModal}
              />}

              <Switch>
                <PrivateRoute exact path={ROUTE_PATH.ROOT} component={() => <Redirect to={ROUTE_PATH.DASHBOARD} />} />
                <PrivateRoute exact path={ROUTE_PATH.ACTIVITY} component={ActivityLandingPage} />
                <PrivateRoute exact path={ROUTE_PATH.ACTIVITY_WITH_ID} component={ActivityDetailPage} />
                <PrivateRoute exact path={ROUTE_PATH.BRIEFING_BOOK} component={BriefingBookPage} />
                <PrivateRoute exact path={ROUTE_PATH.BRIEFING_BOOK_WITH_ID} component={BriefingBookDetailPage} />
                <PrivateRoute exact path={ROUTE_PATH.CONTACT} component={ContactLandingPage} />
                <PrivateRoute exact path={ROUTE_PATH.CONTACT_WITH_ID} component={ContactDetailPage} />
                <PrivateRoute exact path={ROUTE_PATH.CORPORATE_PARTICIPANT_WITH_ID} component={CorporateParticipantPage} />
                <PrivateRoute exact path={ROUTE_PATH.DASHBOARD} component={DashboardContainer} />
                <PrivateRoute exact path={ROUTE_PATH.DASHBOARD_EDIT} component={DashboardContainerConfig} />
                <PrivateRoute exact path={ROUTE_PATH.EVENTS_TRANSCRIPTS} component={EventsTranscripts} />
                <PrivateRoute exact path={ROUTE_PATH.PIPELINE} component={PipelineContainer} />
                <PrivateRoute exact path={ROUTE_PATH.DEAL_WITH_ID} component={DealDetailPage} />
                <PrivateRoute exact path={ROUTE_PATH.ESTIMATES_WITH_SECURITY_ID} component={EstimatesContainer} />
                <PrivateRoute exact path={ROUTE_PATH.FUND_WITH_ID} component={FundPagePreload} />
                <PrivateRoute exact path={ROUTE_PATH.INSTITUTION_WITH_ID} component={InstitutionPagePreload} />

                <PrivateRoute exact path={ROUTE_PATH.ENGAGEMENT_ANALYTICS} component={EngagementPage} />

                {webAnalyticsRoutes}
                {webCastAnalyticsRoutes}

                {isSubscribed(profile.data, 'report_builder') && reportBuilderRoutes}

                <PrivateRoute exact path={ROUTE_PATH.SEARCH} component={SearchPage} />
                <PrivateRoute exact path={ROUTE_PATH.SECURITY_WITH_ID} component={SecurityPagePreload} />
                <PrivateRoute exact path={ROUTE_PATH.TARGETING} component={TargetingComponent} />
                <PrivateRoute exact path={ROUTE_PATH.PEERLIST} component={PeerListLandingPage} />
                <PrivateRoute exact path={ROUTE_PATH.WIDGET} component={WidgetContainer} />

                <Route
                  exact path={ROUTE_PATH.LOGIN}
                  component={() => isLoggedIn
                    ? <Redirect to={ROUTE_PATH.DASHBOARD} />
                    : <LoginContainer auth={auth} history={history} />}
                />
                <Route exact path={ROUTE_PATH.LOGOUT} component={() => getLogoutContainer(auth)} />
                <Route exact path={ROUTE_PATH.PASSWORD_RESET} component={() => <PasswordResetPage auth={auth} />} />
                <PrivateRoute exact path={ROUTE_PATH.ERROR_WITH_CODE} component={ErrorPage} />

                {!isSenchaRoute && (auth && !auth.isLoggedIn()) && <Route component={() => <Redirect to={ROUTE_PATH.LOGIN} />} />}
                {senchaRoutes}
                {!isSenchaRoute && (auth && !auth.isLoggedIn()) && <Route component={() => <Redirect to={ROUTE_PATH.LOGIN} />} />}
                {!isCrmEmailRoute && (auth && !auth.isLoggedIn()) && <Route component={() => <Redirect to={ROUTE_PATH.LOGIN} />} />}
                {!!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL) && <Route exact path={CRM_EMAIL_ROUTE.EMAIL} component={EmailComposerContainer} />}
                {!!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL) && !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL_MANAGE_MENU_OPTION) &&
                <Route exact path={CRM_MANAGE_EMAIL_ROUTE.MANAGE_CONNECTION} component={EmailConnectionManagerContainer} />}

                {/* Handle Page Not Found */}
                <Redirect from='*' to={ROUTE_PATH.PAGE_NOT_FOUND} />
              </Switch>
            </div>
          </>
        )
      }

      {/* TODO: deprecate when security page migration completed */}
      <SenchaPreload
        auth={auth}
        auth0={useAuth0()}
        visible={!showSplash}
      />

      {/* {!!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL) &&
          <EmailAppContainer visible={!showSplash}/>
      } */}
      {/* {!!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL) &&
        !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL_MANAGE_MENU_OPTION) &&
          <EmailConnectionManager visible={!showSplash}/>
      } */}

      <Toast
        message={toast && toast.data}
        deleteToast={handleDeleteToast}
      />
      <div id='app-portal' className='app-portal' />
      <ModalProvider />
    </div>
  )
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  ldClient: PropTypes.object
}

export default withLDConsumer()(withRouter(Root))
