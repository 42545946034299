let getAccessTokenSilently = null
let useAuth0SDK = false

export const LOCAL_AUTH_MODE = {
  KEY: 'localAuthMode',
  VALUE: {
    SSO: 'SSO',
    LEGACY: 'Legacy'
  }
}

export const USED_LOCAL_AUTH_MODE = {
  KEY: 'usedLocalAuthMode'
}

export const auth0Helper = {
  useAuth0SDK: () => useAuth0SDK,
  setUseAuth0SDK: (flag) => { useAuth0SDK = flag },
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func) => (getAccessTokenSilently = func)
}
