import React, { useState, useEffect } from 'react'
import pathToRegexp from 'path-to-regexp'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect, withRouter } from 'react-router-dom'

// components
import { Spinner } from '../../components'
import SenchaRouter from './sencha.container'

// utils
import { THEMES } from '../../utils'
import { get } from 'lodash'

// actions
import { getSecurity, statusType } from '../../actions'

const securityRoutes = [
  '/company/:security',
  '/company/:security/(.*)'
]

const getSecurityPath = (route) => {
  return securityRoutes.map((eachRoute) => {
    const regexp = pathToRegexp(eachRoute)
    const res = regexp.exec(route)
    if (!res) {
      return false
    }
    const [path, securityId] = res || []
    return { path, securityId }
  }).find((item) => item)
}

/**
 * If path contains a bad entity id, the reducer returns an object with an undefined key
 * Returns true if this is the case
 * @param securities
 * @return {boolean}
 */
const isUndefined = (securities) => {
  if (!securities || typeof securities !== 'object') {
    return true
  }
  return Object.keys(securities).includes('undefined')
}

/**
 * securityPagePreload
 * @param props
 */
function SecurityPreload (props) {
  const { getSecurity, security, auth, visible, location, auth0 } = props

  const { data, status } = security

  const { securityId } = getSecurityPath(location.pathname) || {}

  // useState
  const [id, setId] = useState(securityId)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  // If id changes in url path (from quick search, for example), set new id to state and fetch q4 id from api
  useEffect(() => {
    setId(securityId)
  }, [securityId])

  // Check length of path id
  // If it's q4 id, set loading false and render security page
  // If not, fetch security id from iris api
  useEffect(() => {
    if (id && id.length === 32) {
      setLoading(true)
      getSecurity({ entityId: id })
    } else {
      setLoading(false)
    }
  }, [id, getSecurity])

  // If security/q4 id is successfully retrieved, set id to q4_entity_id
  // If not, redirect to 404 page
  useEffect(() => {
    if (status === statusType.SUCCESS) {
      data && data._id ? setId(data._id) : setError(true)
    } else if ((status === statusType.ERROR) || (isUndefined(security) && status === statusType.IN_PROGRESS)) {
      setError(true)
    }
  }, [status, data, security])

  return (
    <>
      {error && <Redirect to='/error/404'/>}
      {loading && <Spinner mask theme={THEMES.WHITE}/>}
      {!loading && (
        <SenchaRouter
          auth={auth}
          auth0={auth0}
          visible={visible}
          locationPathname={securityId ? location.pathname.replace(securityId, id) : location.pathname}
        />
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  location: ownProps.location,
  security: get(state, 'security.security')
})

const mapDispatchToProps = (dispatch) => ({
  getSecurity: bindActionCreators(getSecurity, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecurityPreload))
