import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { config } from '../../config'

const CCAuth0ProviderWrapper = ({ children }) => {
  const domain = config.auth0Domain
  const useFreshTokens = true
  const clientId = config.platformAuth0ClientId
  const redirectUri = `${window.location.origin}/email`
  const audience = config.platformAuth0Audience
  const cacheLocation = 'localstorage'

  return (
    <Auth0Provider
      domain={domain}
      useRefreshTokens={useFreshTokens}
      clientId={clientId}
      redirectUri={redirectUri}
      audience={audience}
      cacheLocation={cacheLocation}
      >
        {children}
    </Auth0Provider>
  )
}

export default CCAuth0ProviderWrapper
