import React, { useState, memo, useEffect } from 'react'
import PropTypes from 'prop-types'

// components
import CustomCell from './cell/cell.component'
import withOptions from '../../../../../utilityMenu/helper/withOptions'
import UtilityMenu from '../../../../../utilityMenu/utilityMenu.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../../index'

// utils
import {
  format,
  formatDate,
  getDefaultColumnDef,
  preventDefaultRowClick,
  inMillions,
  ENTITY_TYPE,
  THEMES,
  getCurrentOwnershipDate
} from '../../../../../../utils'
import { capitalize } from 'lodash'

const Utility = withOptions(UtilityMenu)

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  onQueryChange: PropTypes.func.isRequired,
  holderType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {}
}

const { FUND, INSTITUTION } = ENTITY_TYPE
const EMPTY_PLACEHOLDER = '-'
const ZERO = '0.00'

function formatted (value, formatter = format) {
  return (value || value === 0) ? formatter(value, 0) : EMPTY_PLACEHOLDER
}

/**
 * Get columns based on holderType
 * @param holderType
 */
function getColumns (holderType) {
  const source = (holderType === FUND) ? FUND : INSTITUTION

  return [
    {
      field: 'rank',
      headerName: '#',
      type: 'centered',
      minWidth: 72,
      maxWidth: 72,
      pinned: 'left',
      lockPinned: true,
      sortable: false,
      cellRenderer: 'CustomCell'
    },
    {
      field: `${source}Name`,
      headerName: 'Name',
      type: 'text',
      minWidth: 484,
      pinned: 'left',
      lockPinned: true,
      cellRenderer: 'CustomCell'
    },
    {
      field: 'current',
      headerName: getCurrentOwnershipDate(true),
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => formatted(value)
    },
    {
      field: 'change',
      headerName: 'Chg',
      type: 'change',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => formatted(value)
    },
    {
      field: 'previousQtrPosition',
      headerName: 'Pos',
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => formatted(value)
    },
    {
      field: 'marketValue',
      headerName: 'Mkt Val ($MM)',
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => value ? inMillions(value, 2) : EMPTY_PLACEHOLDER
    },
    {
      field: 'marketValueChange',
      headerName: 'Chg ($MM)',
      type: 'change',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => value ? inMillions(value, 2) : EMPTY_PLACEHOLDER
    },
    {
      field: 'percentTSO',
      headerName: '%OS',
      type: 'number',
      minWidth: 100,
      maxWidth: 100,
      valueFormatter: ({ value }) => value ? format(value, 2) : ZERO
    },
    {
      field: 'percentPortfolio',
      headerName: '%PORT',
      type: 'number',
      minWidth: 100,
      maxWidth: 100,
      valueFormatter: ({ value }) => value ? format(value, 2) : ZERO
    },
    {
      field: `${source}Style`,
      headerName: 'Style',
      type: 'centered',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => value || EMPTY_PLACEHOLDER
    },
    {
      field: `${source}Turnover`,
      headerName: 'Turnover',
      type: 'centered',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => value ? capitalize(value) : EMPTY_PLACEHOLDER
    },
    {
      field: 'institutionTotalAUM',
      headerName: 'AUM ($MM)',
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => formatted(value),
      hide: (source !== INSTITUTION)
    },
    {
      field: 'fundPortfolioValue',
      headerName: 'AUM ($MM)',
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => formatted(value, inMillions),
      hide: (source !== FUND)
    },
    {
      field: `${source}EquityAUM`,
      headerName: 'E AUM ($MM)',
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value, data }) => {
        const { isNested, fundEquityAUM } = (data || {})
        const fundEAUM = isNested ? fundEquityAUM : (source === FUND) ? value : null
        return fundEAUM ? formatted(fundEquityAUM, inMillions) : formatted(value)
      }
    },
    {
      field: 'reportDate',
      headerName: 'As Of',
      type: 'date',
      minWidth: 120,
      maxWidth: 120,
      valueFormatter: ({ value }) => value ? formatDate(value, undefined, true, true) : EMPTY_PLACEHOLDER
    },
    {
      field: `${source}QualityRating`,
      headerName: 'QR',
      type: 'centered',
      minWidth: 80,
      maxWidth: 80,
      valueFormatter: ({ value }) => value || EMPTY_PLACEHOLDER
    }
  ].filter((column) => !column.hide)
}

/**
 * Surveillance Holder Table Component
 * @param props
 */
function SurveillanceHolderTable (props) {
  const { pageSizeId, loading, data, total, listOptions, onQueryChange, holderType, history } = props
  const [agGrid, setAgGrid] = useState({})
  const [utility, setUtility] = useState({
    menu: null,
    items: [
      { action: 'CREATE_ACTIVITY' },
      { action: 'TARGET' },
      { action: 'DOWNLOAD_TEARSHEET' },
      { action: 'BRIEFING_BOOK' }
    ]
  })

  const { menu, items } = (utility || {})
  const columns = getColumns(holderType)

  /**
   * Reset columns on holderType change
   */
  useEffect(() => {
    holderType && agGrid.api && agGrid.api.setColumnDefs([])
  }, [agGrid, holderType])

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param fundId {String}
   * @param institutionId {String}
   * @param holderType {String}
   * @param holdingType {String}
   */
  const handleRowClick = ({ fundId, institutionId, holderType, holdingType }) => {
    const id = (holderType === FUND) ? fundId : institutionId || null
    id && history && history.push(`/${holderType}/${id}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: sortModel[0].colId,
        sortDir: sortModel[0].sort,
        page: 1
      }
    })
  }

  /**
   * Handle utility item click
   * @param event
   * @param entity
   */
  const handleUtilityClick = (event, entity) => {
    event.stopPropagation()

    const { hasDeals } = (entity || {})
    setUtility({
      menu: { entity, anchorEl: event.currentTarget },
      items: items.map((item) => ({ ...item, hide: (hasDeals && item.action === 'TARGET') }))
    })
  }

  /**
   * Render Utility Menu
   */
  const getUtilityMenu = () => {
    const { menu, items } = utility
    const { entity, anchorEl } = (menu || {})

    return entity
      ? <Utility
          theme={THEMES.LIGHT}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleUtilityClose()}
          items={items.filter((item) => !item.hide)}
          entityType={entity.entityType}
          entity={entity}
          hasButton
        />
      : null
  }

  /**
   * Handle Utility Close Click
   */
  const handleUtilityClose = () => {
    setUtility({ ...utility, menu: null })
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} onUtilityClick={handleUtilityClick} />
  }

  /**
   * Returns grid columns
   */
  const getColumnsDefinition = () => {
    return columns.map((column, idx) => {
      const { type } = column
      return {
        ...getDefaultColumnDef({ columnIndex: ((idx === 1) ? idx + 1 : idx), type }),
        ...column
      }
    })
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage />
        : <AgGrid
            domLayout='autoHeight'
            sizeToFit
            // suppress configs
            suppressMovableColumns
            suppressContextMenu
            // columns and data
            defaultColDef={{
              suppressMenu: true,
              sortable: true
            }}
            columnDefs={getColumnsDefinition()}
            rowData={data}
            // pagination
            pagination
            paginationProps={{
              pageSizeId,
              forcePage: listOptions.page,
              initialPageSize: listOptions.limit,
              showPageSizeSelection: true,
              total,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange
            }}
            // custom components
            frameworkComponents={{
              CustomCell: renderCustomCell
            }}
            // event listeners
            onGridReady={handleGridReady}
            onGridSizeChanged={handleGridResize}
            onSortChanged={handleSortChange}
            onRowClicked={preventDefaultRowClick(
              handleRowClick, ['cell_indicator--utility']
            )}
            isPinned={data.length}
          />}

      {menu && getUtilityMenu()}
    </div>
  )
}

SurveillanceHolderTable.propTypes = propTypes
SurveillanceHolderTable.defaultProps = defaultProps

export default memo(SurveillanceHolderTable)
