import { isEmpty } from "lodash";

/**
 * 
 * @param {string} route 
 * @param  {...{ label: string, value: string }} params 
 * @returns {string}
 */
export function mapRoutePath(route, ...params) {
  if (isEmpty(params)) return null;

  return params.reduce((route, param) => {
    return isEmpty(param) ? route : route.replace(param.label, param.value);
  }, route ?? "");
}
