import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get, isNull, omitBy, capitalize, sortBy } from 'lodash'
import PropTypes from 'prop-types'

// actions
import { loadPeers, openModal, modalType } from '../../../../../actions'
import { useSecurityPeerAnalysisQuery, GET_SECURITY_PEER_ANALYSIS } from '../../../hook'
import { useExport } from '../../../../../services/csvExport/csvExport.service'

// components
import HolderPeerAnalysisToolbar from './toolbar/toolbar.component'
import HolderPeerAnalysisTable from './table/table.component'

// utils
import {
  getPageSizeFromStorage,
  ENTITY_TYPE,
  getLocalStorageItem,
  getFromXigniteToStandard,
  isReportingWindow,
  YET_TO_FILE,
  format
} from '../../../../../utils'

const { INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string,
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  security: PropTypes.object.isRequired
}

const defaultProps = {}

const PAGE_SIZE_ID = 'holder-peer-analysis-grid'
const EMPTY_PLACEHOLDER = '-'

/**
 * Holders Peer Analysis Grid Component
 * @param props
 */
function HolderPeerAnalysisGrid (props) {
  const { tickerId, security, dataIdPrefix, toolbarTheme, toolTheme, openModal, loadPeers, history, primaryTicker } = props

  const [holder, setHolder] = useState(INSTITUTION)
  const [state, setState] = useState({
    peers: [],
    metric: 'current',
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  })
  const { search, metric, listOptions, peers } = state

  /**
   * Set peers on initial load (from local storage or get peers)
   */
  useEffect(() => {
    const peers = getLocalStorageItem('peers')
    if (!state.peers.length) {
      if (peers) {
        peers.length && setState({ ...state, peers })
      } else {
        const peerParams = {
          select: ['symbol', 'exchange', 'security_name', 'q4_entity_id', 'q4_ticker_id'],
          sortField: 'Symbol',
          sortOrder: 1,
          limit: 20
        }
        loadPeers(peerParams).then(({ payload }) => {
          payload && payload.length && setState({ ...state, peers: payload })
        })
      }
    }
  }, [state, loadPeers])

  const peerTickerIdList = peers.map((peer) => peer.q4_ticker_id)
  if (!peerTickerIdList.includes(primaryTicker.q4_ticker_id)) {
    peerTickerIdList.push(primaryTicker.q4_ticker_id)
  }
  const variables = omitBy({
    tickerId,
    peerTickerId: peerTickerIdList,
    search,
    metric,
    entityType: holder,
    ...listOptions
  }, isNull)

  const { data, loading, refetch, client } = useSecurityPeerAnalysisQuery({ variables })
  const analysis = get(data, 'securityPeerAnalysis.items')
  const total = get(data, 'securityPeerAnalysis.count', 0)

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
  }

  /**
   * Update peers on peer list update
   * @param securities
   */
  const updatePeers = (securities) => {
    handleQueryChange({
      peers: securities || []
    })
  }

  /**
   * Handle peer list update
   */
  const handlePeerUpdate = () => {
    openModal({
      type: modalType.PEER_CONFIG_MODAL,
      props: {
        rememberSelectionKey: 'peers',
        excludeMyCompany: false,
        onSave: ({ peers }) => {
          updatePeers(peers)
        }
      }
    })
  }

  /**
   * Returns a copy of a holding with updated exchange and ticker id according to sec id and exchange
   * @param holding
   * @param securityId
   * @param exchange
   * @param tickerId
   * @returns {Array}
   */
  const _getAdditionHolding = (holding, securityId, exchange, tickerId) => {
    if (securityId === holding.securityId && holding.exchange !== exchange) {
      return [{
        ...holding,
        ...{
          exchange: exchange,
          tickerId: tickerId
        }
      }]
    }
    return []
  }

  /**
   * Modify existing entities with new holdings if shared security is present in peer list
   * Add primary tickerId to dataset if it is not a peer
   * @param entity
   */
  const syncWithPeers = (entity) => {
    return ({
      ...entity,
      holdings: entity.holdings?.reduce((holdingData, holding) => {
        const headingTickerIdList = [primaryTicker.q4_ticker_id, ...peers.reduce((a, c) => [...a, c.q4_ticker_id], [])]
        const additionalHoldingData = [holding].filter((exchange) => headingTickerIdList.includes(exchange.tickerId))

        additionalHoldingData.push(..._getAdditionHolding(holding, security.id, security.exchangeCode, security.tickerId))
        peers.forEach((peer) => {
          additionalHoldingData.push(..._getAdditionHolding(holding, peer.q4_entity_id, peer.exchange, peer.q4_ticker_id))
        })

        return holdingData.concat(additionalHoldingData)
      }, [])
    })
  }

  /**
   * Map through the returned data and return new dataset with synced securities with peers
   * @param data
   */
  const syncSharedSecuritiesPeers = (data) => {
    return data ? data.map((entity) => syncWithPeers(entity)) : data
  }

  /**
   * return the column header name for each security and exchange
   * @param {string} symbol
   * @param {string} exchangeCode
   * @returns {string}
   */
  const getColumnHeaderName = (symbol, exchangeCode) => {
    return `${symbol} ${getFromXigniteToStandard(exchangeCode)}`
  }

  /**
   * Return the key for each column with exchange and metric
   * @param {string} securityId
   * @param {string} exchangeCode
   * @param {string} metric
   * @returns {string}
   */
  const getHoldingFieldKey = (securityId, exchangeCode, metric) => {
    return `${securityId}_${exchangeCode}_${metric}`
  }

  /**
   * Return the peer's holding value
   * @param {*} holding
   * @returns {string}
   */
  const getHoldingValue = (holding) => {
    const value = holding?.value
    const isYetToFile = isReportingWindow() && value === 0
    return isYetToFile ? YET_TO_FILE : format(value, 0) || EMPTY_PLACEHOLDER
  }

  /**
   * Format mapper for CSV export
   * @param {*} peerData
   * @returns {object}
   */
  const peerAnalysisCSVMapper = (peerData) => {
    const extendedPeerData = syncWithPeers(peerData)
    const entityType = get(extendedPeerData, 'entityType', null)
    const entityName = get(extendedPeerData, 'entityName', null)
    const holdings = get(extendedPeerData, 'holdings', [])

    const securityHolding = peerData?.holdings?.find((holding) => holding.tickerId === primaryTicker.q4_ticker_id)

    const peerHoldingKeys = holdings.reduce((obj, holding) => {
      obj[getHoldingFieldKey(holding.securityId, holding.exchange, metric)] = getHoldingValue(holding)
      return obj
    }, {})

    const sortedPeers = sortBy(peers, (peer) => getColumnHeaderName(peer.symbol, peer.exchange))

    const peerNameValueObject = sortedPeers.reduce((acc, peer) => {
      const holdingFieldKey = getHoldingFieldKey(peer.q4_entity_id, peer.exchange, metric)
      acc[getColumnHeaderName(peer.symbol, peer.exchange)] = peerHoldingKeys[holdingFieldKey]
      return acc
    }, {})

    if (entityType && entityName) {
      return {
        [capitalize(entityType)]: entityName,
        [getColumnHeaderName(primaryTicker.symbol, primaryTicker.exchange)]: getHoldingValue(securityHolding),
        ...peerNameValueObject
      }
    }
    return {}
  }

  const { generateExport, exporting } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  const handleExport = () => {
    const params = {
      client,
      // pass the tickerId in peerTickerId to avoid the API default get user peer list behavior
      variables: { ...variables, limit: 0 },
      query: GET_SECURITY_PEER_ANALYSIS,
      dataPath: 'data.securityPeerAnalysis.items',
      fileName: `${security.symbol}_${security.exchangeName}_peer_analysis_${holder}.csv`,
      formatter: peerAnalysisCSVMapper
    }

    generateExport(params)
  }

  return (
    <>
      <HolderPeerAnalysisToolbar
        dataId={`${dataIdPrefix}PeerAnalysis`}
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
        metric={metric}
        holder={holder}
        noData={!analysis || !analysis.length}
        onHolderChange={(holder) => setHolder(holder)}
        onQueryChange={handleQueryChange}
        onPeerUpdate={handlePeerUpdate}
        onExport={handleExport}
        exporting={exporting}
      />
      <HolderPeerAnalysisTable
        pageSizeId={PAGE_SIZE_ID}
        loading={loading}
        data={syncSharedSecuritiesPeers(analysis)}
        total={total}
        listOptions={listOptions}
        onQueryChange={handleQueryChange}
        onPeerUpdate={handlePeerUpdate}
        history={history}
        security={security}
        peers={peers}
        onRefresh={refetch}
        metric={metric}
        primaryTicker={primaryTicker}
      />
    </>
  )
}

HolderPeerAnalysisGrid.propTypes = propTypes
HolderPeerAnalysisGrid.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const primaryTicker = get(state, 'profile.data._organization.tickers', []).filter(ticker => ticker?.active || ticker?.primary)[0]

  return {
    primaryTicker,
    status: get(state, 'peer.status'),
    peerlist: get(state, 'peer.data')
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadPeers: bindActionCreators(loadPeers, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HolderPeerAnalysisGrid))
